<template>
    <b-container v-if="item != null">
        <b-row id="titre">
            <b-col
            ><h4>Evolution de la population {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="8" v-if="pop_iris_array && pop_com_array">
                <apexchart
                  type="line"
                  height="350"
                  :series="evoLineSeries"
                  :options="Options"
                ></apexchart>
            </b-col>
            <b-col col md="4"
            >
                <div>
                    Evolution de
                    {{ annee_tab[0] + '-' + annee_tab[annee_tab.length - 1] }}
                </div>
                <div>
                    {{
                        verify_null_percent(
                          (
                            (pop_iris_array[pop_iris_array.length - 1] -
                              pop_iris_array[0]) /
                            pop_iris_array[0]
                          ).toFixed(3),
                          1,
                          true
                        )
                    }}
                </div>
                <div>
                    Evolution sur la commune de
                    {{ annee_tab[0] + '-' + annee_tab[annee_tab.length - 1] }}
                </div>
                <div>
                    {{
                        verify_null_percent(
                          (
                            (pop_com_array[pop_com_array.length - 1] - pop_com_array[0]) /
                            pop_com_array[0]
                          ).toFixed(3),
                          1,
                          true
                        )
                    }}
                </div>
            </b-col>
        </b-row>
        <b-row
        >
            <b-col>
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-tr>
                        <b-th>{{ item.nom_iris }}</b-th>
                        <b-th v-for="(item, index) in annee_tab" :key="index">{{
                                item
                            }}
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-th>Population</b-th>
                        <b-td v-for="(item, index) in pop_iris_array" :key="index">{{
                                item
                            }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Évolution</b-th>
                        <b-td v-for="(item, index) in pop_iris_array" :key="index">
              <span
                :style="{'color': changeColor(evolution_iris(item, index))}"
              >
                {{ index === 0 ? '-' : evolution_iris(item, index) }}
              </span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Population de la commune</b-th>
                        <b-td v-for="(item, index) in pop_com_array" :key="index">{{
                                parseFloat(item, 10).toLocaleString('fr')
                            }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Évolution sur la commune</b-th>
                        <b-td v-for="(item, index) in pop_com_array" :key="index">
              <span :style="{'color': changeColor(evolution_com(item, index))}">
                {{ index === 0 ? '-' : evolution_com(item, index) }}
              </span>
                        </b-td>
                    </b-tr>
                </b-table-simple>
            </b-col>
        </b-row
        >
    </b-container>
</template>

<script>
import {
    color_evolution,
    verify_null_percent
} from '@/components/functions_utilities';

export default {
    name: 'Population',
    data()
    {
        return {
            item: null,
            annee_tab: [],
            pop_iris_array: [],
            pop_com_array: [],
            test: [this.pop_iris_array, this.pop_com_array]
        };
    },
    methods: {
        verify_null_percent,
        evolution_iris: function (item, index)
        {
            let current = item;
            let before = this.pop_iris_array[index - 1];
            return verify_null_percent(
              ((current - before) / before).toFixed(3),
              1,
              true
            );
        },
        evolution_com: function (item, index)
        {
            let current = item;
            let before = this.pop_com_array[index - 1];
            return verify_null_percent(
              ((current - before) / before).toFixed(3),
              1,
              true
            );
        },
        middleware: function ()
        {
            let truc_iris = [];
            let truc_com = [];
            let annee = [];
            for (const element of this.item.selected_pop_iris)
            {
                truc_iris.push(element.pop);
                annee.push(element.annee);
            }
            for (const element of this.item.commune.selected_pop_com)
            {
                truc_com.push(element.pop);
            }
            this.pop_iris_array = [...truc_iris];
            this.pop_com_array = [...truc_com];
            this.annee_tab = [...annee];
        },
        changeColor(value)
        {
            if (value.startsWith('+')) return 'green'
            else if (value === "-") return 'black'
            else if (value.startsWith('-') && value !== "-") return 'red'
        }
    },
    computed: {
        evoLineSeries: function ()
        {
            return [
                {
                    name: 'Population',
                    data: this.pop_iris_array
                },
                {
                    name: 'Population Commune',
                    data: this.pop_com_array
                }
            ];
        },
        Options: function ()
        {
            // this.middleware();
            return {
                dataLabels: {
                    enabled: true
                },
                markers: {
                    size: 6
                },
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: this.annee_tab
                },
                yaxis: [
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.pop_iris_array),
                        max: Math.max(...this.pop_iris_array),
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB'
                            }
                        },
                        title: {
                            text: 'Population',
                            style: {
                                color: '#008FFB'
                            }
                        }
                    },
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.pop_com_array),
                        max: Math.max(...this.pop_com_array),
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            style: {
                                colors: '#00E396'
                            }
                        },
                        title: {
                            text: 'Population commune',
                            style: {
                                color: '#00E396'
                            }
                        }
                    }
                ]
            };
        }
    },
    created()
    {
        this.unwatch = this.$store.watch(
          (state, getters) => getters.getSelected_item,
          (newValue, oldValue) =>
          {
              newValue.selected_pop_iris.sort((a, b) => a.annee - b.annee);
              this.item = newValue;
              this.middleware();
          }
        );
    },
    beforeDestroy()
    {
        this.unwatch();
    }
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

#TEST {
    background-color: aqua;
    position: absolute;
    width: 400px;
    height: 200px;
    bottom: 0px;
}
</style>
