<template>
    <b-container v-if="item && !animation_load">
        <b-row id="titre">
            <b-col col md="9">
                <h4>Structure de la population à {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row
        >
            <b-col>
                <b-form-radio-group
                  style="margin-bottom:10px;"
                  id="btnradios3"
                  buttons
                  button-variant="outline-secondary"
                  v-model="annee_selected"
                  :options="annee_tab"
                  name="radioBtn"/>
            </b-col
            >
        </b-row>
        <b-row>
            <b-col v-if="iris_header_arrays[annee_selected]" col md="6">
                <p class="paragraphe">
                    Part de la population selon la catégorie socioprofessionnelle </p>
                <div id="pie_chart_1">
                    <apexchart
                      type="donut"
                      :options="chartOptions"
                      :series="iris_header_arrays[annee_selected]"
                    ></apexchart>
                </div>
            </b-col>
            <b-col v-if="bar_series.length !== 0" col md="6">
                <p class="paragraphe">
                    Évolution de la population selon la catégorie socioprofessionnelle </p>
                <div id="bar_chart_1">
                    <apexchart
                      type="bar"
                      :options="chartOptions1"
                      :series="bar_series"
                    ></apexchart>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table-simple responsive>
                    <b-thead>
                        <b-tr>
                            <b-th>CSP</b-th>
                            <b-th v-for="(item, index) in header_tab" :key="index">{{
                                    item
                                }}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tr v-for="(item, index) in annee_tab" :key="index">
                        <b-th>{{ item }}</b-th>
                        <b-td
                          v-for="(item_2, index_2) in iris_header_arrays[item]"
                          :key="index_2"
                        >{{ item_2 }}
                        </b-td
                        >
                    </b-tr>
                    <b-tr>
                        <b-th>Évolution nette</b-th>
                        <b-td
                          v-for="(item, index) in header_tab"
                          :key="index"
                          style="padding:0 auto;">
              <span :style="{'color' : changeColor(evolution_iris(index))}">
                {{
                      Object.keys(iris_header_arrays).length !== 0
                        ? evolution_iris(index)
                        : 'Inconnu'
                  }}
              </span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Évolution nette commune</b-th>
                        <b-td v-for="(item, index) in header_tab" :key="index">
            <span :style="{'color' : changeColor(evolution_com(index))}">
              {{
                    Object.keys(com_array).length !== 0
                      ? evolution_com(index)
                      : 'Inconnu'
                }}
            </span>
                        </b-td>
                    </b-tr>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <v-skeleton-loader type="card-avatar, article, actions"></v-skeleton-loader>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'Structure',
    data()
    {
        return {
            animation_load: true,
            annee_selected: null,
            annee: null,
            annee_tab: [],
            bar_series: [],
            bar_pre_series: {},
            iris_header_arrays: {},
            com_array: [],
            selected_iris_annee: null,
            chartOptions: {
                height: '300px',
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true
                        }
                    }
                },
                labels: [
                    'Agriculteurs exploitants',
                    'Artisans, Comm., Chefs entr',
                    'Cadres, Prof. intel. sup',
                    'Prof. intermédiaires',
                    'Employés',
                    'Ouvriers',
                    'Retraités',
                    'Autres'
                ],
                legend: {
                    show: true,
                    position: 'left',
                    horizontalAlign: 'left'
                },
                colors: [
                    '#FFA400',
                    '#345995',
                    '#E40066',
                    '#03CEA4',
                    '#FB4D3D',
                    '#775DD0',
                    '#00D9E9',
                    '#FF66C3'
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 200,
                                width: 200
                            },
                            legend: {
                                position: 'left',
                                horizontalAlign: 'left'
                            }
                        }
                    }
                ]
            },
            header_tab: [
                'Agriculteurs exploitants',
                'Artisans, Comm., Chefs entr',
                'Cadres, Prof. intel. sup',
                'Prof. intermédiaires',
                'Employés',
                'Ouvriers',
                'Retraités',
                'Autres'
            ]
        };
    },
    methods: {
        evolution_iris: function (index)
        {
            let keys_iris = Object.keys(this.iris_header_arrays);
            let current = this.iris_header_arrays[keys_iris[keys_iris.length - 1]][
              index
              ];
            let before = this.iris_header_arrays[keys_iris[0]][index];
            let resultat = parseFloat(
              (((current - before) / before) * 100.0).toFixed(1)
            );
            if (isFinite(resultat))
            {
                if (resultat > 0) return '+' + resultat + '%';
                else return resultat + ' %';
            }
            else
            {
                if (before === 0)
                {
                    return '-';
                }
                else return 'Inconnu';
            }
        },
        evolution_com: function (index)
        {
            let keys_com_temp = Object.keys(this.bar_pre_series);
            let keys_com = keys_com_temp[index];

            let last = this.com_array[keys_com_temp.length - 1][keys_com];
            let first = this.com_array[0][keys_com];

            let resultat = parseFloat((((last - first) / first) * 100.0).toFixed(1));
            if (isFinite(resultat))
            {
                if (resultat > 0) return '+' + resultat + '%';
                else return resultat + ' %';
            }
            else
            {
                if (first === 0)
                {
                    return '-';
                }
                else return 'Inconnu';
            }
        },
        reloadSelected_item()
        {
        },
        changeAnnee_filter()
        {
            if (this.$store.getters.getAnnee_filter != null)
            {
                this.annee = this.$store.getters.getAnnee_filter;
            }
        },
        refresh_item(newValue)
        {
            let temp = [];
            let truc_iris = {};
            let truc_com = [];
            let annee = [];
            for (let index = 1; index <= 8; index++)
            {
                this.bar_pre_series[
                'pop15p_cs' + index
                  ] = this.item.selected_pop_iris.map(a => a['pop15p_cs' + index]);
            }
            this.bar_series_func();
            for (const [index, element] of this.item.selected_pop_iris.entries())
            {
                temp.push(
                  element.pop15p_cs1,
                  element.pop15p_cs2,
                  element.pop15p_cs3,
                  element.pop15p_cs4,
                  element.pop15p_cs5,
                  element.pop15p_cs6,
                  element.pop15p_cs7,
                  element.pop15p_cs8
                );
                truc_iris[element.annee] = [...temp];
                temp = [];
                annee.push(element.annee);
            }
            for (const element of this.item.commune.selected_pop_com)
            {
                truc_com.push(element);
            }
            this.com_array = [...truc_com];
            this.iris_header_arrays = {...truc_iris};
            this.annee_tab = [...annee];
            this.animation_load = false;
        },
        bar_series_func()
        {
            this.bar_series = [
                {
                    name: 'Agriculteurs exploitants',
                    data: this.bar_pre_series.pop15p_cs1
                },
                {
                    name: 'Artisans, Comm., Chefs entr',
                    data: this.bar_pre_series['pop15p_cs2']
                },
                {
                    name: 'Cadres, Prof. intel. sup',
                    data: this.bar_pre_series['pop15p_cs3']
                },
                {
                    name: 'Prof. intermédiaires',
                    data: this.bar_pre_series['pop15p_cs4']
                },
                {
                    name: 'Employés',
                    data: this.bar_pre_series['pop15p_cs5']
                },
                {
                    name: 'Ouvriers',
                    data: this.bar_pre_series['pop15p_cs6']
                },
                {
                    name: 'Retraités',
                    data: this.bar_pre_series['pop15p_cs7']
                },
                {
                    name: 'Autres',
                    data: this.bar_pre_series['pop15p_cs8']
                }
            ];
        },
        changeColor(value)
        {
            if (value.startsWith('+')) return 'green'
            else if (value === "-") return 'black'
            else if (value.startsWith('-') && value !== "-") return 'red'
        }
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        chartOptions1()
        {
            return {
                chart: {
                    stacked: true,
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true
                        }
                    },
                    zoom: {
                        enabled: true
                    }
                },
                colors: [
                    '#FFA400',
                    '#345995',
                    '#E40066',
                    '#03CEA4',
                    '#FB4D3D',
                    '#775DD0',
                    '#00D9E9',
                    '#FF66C3'
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                show: false
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: this.annee_tab
                },
                legend: {
                    show: false
                },
                fill: {
                    opacity: 1
                }
            };
        },
        iris_header()
        {
            return this.iris_header_arrays[this.annee_selected];
        }
    },
    created()
    {
    },
    watch: {
        annee_tab: function (newValue)
        {
            if (newValue.length !== 0)
            {
                this.annee_selected = newValue[newValue.length - 1];
                this.annee = newValue[newValue.length - 1];
            }
        },
        item: function (newValue)
        {
            this.refresh_item(newValue);
        },
        annee_selected: function ()
        {
            this.selected_iris_annee = this.item.selected_pop_iris.find(
              x => x['annee'] === this.annee_selected
            );
        }
    },
    beforeMount()
    {
        this.changeAnnee_filter();
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0;
    }
}

#TEST {
    background-color: aqua;
    position: absolute;
    width: 400px;
    height: 200px;
    bottom: 0px;
}

#pie_chart_1 .apexcharts-legend {
    text-align: left;
}

.paragraphe {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}
</style>
