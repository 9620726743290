<template>
    <b-container v-if="items.length != 0">
        <b-row>
            <b-col>
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-thead>
                        <b-tr>
                            <b-th>Date</b-th>
                            <b-th>Mutation</b-th>
                            <b-th>Désignation</b-th>
                            <b-th>Surface réelle batie</b-th>
                            <b-th>Pièces</b-th>
                            <b-th>Valeur</b-th>
                            <b-th>Valeur au m²</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="item in items" :key="item.id">
                            <b-td>{{ formatDate(item.date_mutation) }}</b-td>
                            <b-td>{{ item.nature_mutation }}</b-td>
                            <b-td>{{ item.type_local }}</b-td>
                            <b-td>{{ item.surface_reelle_batie }} m²</b-td>
                            <b-td>{{ item.nombre_pieces }}</b-td>
                            <b-td>{{ verify_null_currency(item.valeur_fonciere) }}</b-td>
                            <b-td>{{ verify_null_currency(item.pmc, '/m²') }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>

		<div v-if="config_selected.page === 'marche_neuf_geoloc'">
			<b-col col md="12">
				<h4>Moyennes de prix par typologie :</h4>
			</b-col>
			<b-row v-if="typologies">
				<b-col>
					<b-table-simple class="table" responsive bordered fixed small>
						<b-thead>
							<b-tr >
								<b-th v-for="item in typologies" :key="item.id">{{ item.typo }}</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td v-for="item in typologies" :key="item.id">
									<p  v-if="!isNaN((item.moyenne.reduce((partialSum, a) => partialSum + a, 0) / item.moyenne.length))">
										{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.moyenne.reduce((partialSum, a) => partialSum + a, 0) / item.moyenne.length) }}
									</p>
									<p v-else >Pas assez de données</p>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
		</div>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_currency} from './functions_utilities';

export default {
    name: 'MarcheAncienGeoloc',
    data()
    {
        return {
            header_top: [
                'Studio',
                '2 pièces',
                '3 pièces',
                '4 pièces',
                '5 pièces et +'
            ],
            items: [],
			typologies: {
				T1: { typo: 'T1', moyenne: []},
				T2: { typo: 'T2', moyenne: []},
				T3: { typo: 'T3', moyenne: []},
				T4: { typo: 'T4', moyenne: []},
				T5: { typo: 'T5', moyenne: []},
			}
        };
    },
    methods: {
        verify_null_currency,
        formatDate(date)
        {
            if (!date)
            {
                return date
            }
            date = date.split('-');
            return [date[2], date[1], date[0]].join('-');
        },
    },
    watch: {
        '$store.getters.getSelected_item': function (newValue)
        {
            Array.isArray(newValue) === true
              ? (this.items = newValue)
              : this.items.push(newValue);

			this.items.forEach(item => {
				this.typologies['T'+item.nombre_pieces].moyenne.push(item.valeur_fonciere)
			})
        },
    },
	computed: {
		...mapGetters({
			config_selected: 'getConfig_selected'
		}),
	}
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.sub_title {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}

.div_paragraphe {
    display: flex;
    justify-content: flex-start;

    .paragraphe {
        display: inline-block;
        width: auto;
        text-align: left;
        border-bottom: 1px red solid;
    }
}
</style>
