<template>
    <div class="vh-100 vw-100 d-flex" id="main">
        <div>
            <Sidebar @open_instant="open_instant()"/>
        </div>
        <b-modal
          v-model="modalShow"
          ref="pdf_modal"
          id="modal-xl"
          size="xl"
          hide-footer
        >
            <PDFView
              :src.sync="pdfBase64"
              ref="pdfView"
              fileName="RapportInovefa"
              :sidebarFeatureVisible="false"
              :downloadFeatureVisible="true"
              :dropzoneFeatureVisible="false"
              :toolbarVisible="true"
              scale.sync="1.25"
            >
                <template slot="right-toolbox"></template>
                <template slot="left-toolbox"></template>
                <template slot="error"></template>
                <template slot="loading"></template>
            </PDFView>
        </b-modal>
        <div class="h-100 w-100 d-flex flex-wrap justify-content-center">
            <div class="h-100 w-100 d-print-none">
                <Map></Map>
            </div>
            <div class="truc" v-if="sheetSelected.sheet">
                <v-bottom-sheet
                  v-model="sheet"
                  hide-overlay
                  scrollable
                  v-bind="return_fullscreen"
                  width="70%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on">
                            Analyse
                        </v-btn>
                    </template>

                    <!-- la condition avec sheet est pour détruire le compopent à chaque fermeture du bottom-sheet -->
                    <v-card v-if="sheet">
                        <v-card-actions id="bandeau" class="d-print-none">
                            <v-btn class="mt-0 button" text @click="sheet = !sheet">
                                Fermer
                            </v-btn>
                            <v-btn class="mt-0 button" text @click="toggle_fullscreen()"
                            >Plein écran
                            </v-btn
                            >
                        </v-card-actions>
                        <v-card-text style="height:400px;" class="d-print-inline-flex">
                            <CapAchat v-if="sheetSelected.page === 'cap_achat'"/>
                            <Gentrification v-if="sheetSelected.page === 'gentrification'"/>
                            <MarcheLocatif v-if="sheetSelected.page === 'marche_locatif'"/>
                            <MarcheAncien v-if="sheetSelected.page === 'marche_ancien'"/>
                            <ParcSocial v-if="sheetSelected.page === 'parc_social'"/>
                            <ZoneTva v-if="sheetSelected.page === 'zone_tva'"/>
                            <ZonePeb v-if="sheetSelected.page === 'zone_peb'"/>
                            <OperationAmen v-if="sheetSelected.page === 'operation_amen'"/>
                            <ProgrammeNeuf v-if="sheetSelected.page === 'programmes_neufs'"/>
                            <Pinel v-if="sheetSelected.page === 'pinel'"/>
                            <CouvertureFibre v-if="sheetSelected.page === 'couverture_fibre'"/>
                            <Mensualite v-if="sheetSelected.page === 'mensualite'"/>
                            <PLU v-if="sheetSelected.page === 'PLU'"/>
                            <MenageSolvable v-if="sheetSelected.page === 'menage_solvable'"/>
                            <Location v-if="sheetSelected.page === 'location'"/>
                            <TaxeFonciere v-if="sheetSelected.page === 'taxes_foncieres'"/>
                            <LogementSocial
                              v-if="sheetSelected.page === 'logements_sociaux'"
                            />
                            <PermisConstruire
                              v-if="sheetSelected.page === 'permis_construire'"
                            />
                            <MarcheAncienGeoloc
                              v-if="sheetSelected.page === 'marche_ancien_geoloc' || sheetSelected.page === 'marche_neuf_geoloc'"
                            />

                            <StructureCom
                              v-if="sheetSelected.page === 'struct_pop_com' &&
                                    sheetSelected.second.active === false"/>
                            <PrecoCom
                              v-if="sheetSelected.page === 'preco_com' &&
                                    sheetSelected.second.active === false"/>
                            <ParcImmoCom
                              v-if="sheetSelected.page === 'parc_immo_com' &&
                                    sheetSelected.second.active === false"/>
                            <PopulationCom
                              v-if="sheetSelected.page === 'evo_pop_com' &&
                                    sheetSelected.second.active === false"/>

                            <div v-if="sheetSelected.second">
                                <Population
                                  v-if="sheetSelected.second.page === 'evo_pop_iris' &&
                                        sheetSelected.second.active"/>
                                <ParcImmo
                                  v-if="sheetSelected.second.page === 'parc_immo_iris' &&
                                        sheetSelected.second.active"/>
                                <Structure
                                  v-if="sheetSelected.second.page === 'struct_pop_iris' &&
                                        sheetSelected.second.active"/>
                                <PrecoIris
                                  v-if="sheetSelected.second.page === 'preco_iris' &&
                                        sheetSelected.second.active"/>
                            </div>

                            <v-divider></v-divider>
                            <div id="footer_analyse_sheet">
                                <h4>Sources des données</h4>
                                <ul
                                  id="footer_analyse_sheet_list"
                                  style="list-style-position: inside;"
                                >
                                    <li
                                      v-for="(source, index) in sheetSelected.sources"
                                      :key="index"
                                      style="text-align: left;"
                                    >
                                        {{ source }}
                                    </li>
                                </ul>
                                <span style="float: left;">Généré depuis l'application EDII by Inovefa (https://inovefa-edii.com)</span>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-bottom-sheet>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../components/Sidebar';
import Map from '../components/Map';
import Population from '../components/Population';
import Structure from '../components/Structure';
import CapAchat from '../components/CapAchat';
import Gentrification from '../components/Gentrification';
import PrecoCom from '../components/PrecoCom';
import MarcheLocatif from '../components/MarcheLocatif';
import ParcSocial from '../components/ParcSocial';
import PrecoIris from '../components/PrecoIris';
import MarcheAncien from '../components/MarcheAncien';
import ParcImmo from '../components/ParcImmo';
import ZoneTva from '../components/ZoneTva';
import OperationAmen from '../components/OperationAmen';
import ProgrammeNeuf from '../components/ProgrammeNeuf';
import Pinel from '../components/Pinel';
import Mensualite from '../components/Mensualite';
import PLU from '../components/PLU';
import MenageSolvable from '../components/MenageSolvable';
import Location from '../components/Location';
import TaxeFonciere from '../components/TaxeFonciere';
import MarcheAncienGeoloc from '../components/MarcheAncienGeoloc';
import LogementSocial from '../components/LogementSocial';
import PermisConstruire from '../components/PermisConstruire';
import ParcImmoCom from '../components/ParcImmoCom';
import StructureCom from '../components/StructureCom';
import PopulationCom from '../components/PopulationCom';
import ZonePeb from '../components/ZonePeb';
import CouvertureFibre from '../components/CouvertureFibre';
import {PDFView} from 'vue_pdfjs_viewer';

export default {
    name: 'Home',
    components: {
        Sidebar,
        Map,
        Population,
        Structure,
        CapAchat,
        Gentrification,
        PrecoCom,
        MarcheLocatif,
        ParcSocial,
        PrecoIris,
        MarcheAncien,
        ParcImmo,
        ZoneTva,
        ZonePeb,
        OperationAmen,
        ProgrammeNeuf,
        Pinel,
        Mensualite,
        PLU,
        MenageSolvable,
        Location,
        TaxeFonciere,
        PDFView,
        MarcheAncienGeoloc,
        LogementSocial,
        PermisConstruire,
        ParcImmoCom,
        StructureCom,
        PopulationCom,
        CouvertureFibre,
    },
    data()
    {
        return {
            sheet: false,
            test: false,
            isAdmin: null,
            searchMarker: null,
            fullscreen_: false,
            inset_: true,
            name: null
        };
    },

    created()
    {
        this.unsubscribe = this.$store.subscribeAction((action, state) =>
        {
            action.type === 'actionSelected_item'
              ? (this.sheet = true)
              : (this.sheet = false);
        });
    },
    mounted()
    {
        this.$store.commit('set_alert_snackbar', {
            content: 'Vous êtes connecté !',
            color: 'red',
            type: 'success'
        });
    },
    beforeDestroy()
    {
        this.unsubscribe();
    },
    watch: {
    },
    methods: {
        open_instant()
        {
            this.sheet = true;
        },
        condition_sheet(nameComp)
        {
        },
        toggle_fullscreen()
        {
            this.inset_ = !this.inset_;
            this.fullscreen_ = !this.fullscreen_;
        },
        print()
        {
            window.print();
        }
    },
    computed: {
        return_fullscreen()
        {
            return {
                inset: this.inset_,
                fullscreen: this.fullscreen_
            };
        },
        data_geo()
        {
            return this.$store.getters.getData_geo;
        },
        sheetSelected()
        {
            return this.$store.getters.getConfig_selected;
        },
        modalShow: {
            get()
            {
                return this.$store.getters.pdf_modal_show;
            },
            set(val)
            {
                this.$store.commit('set_pdf_modal_show', false);
                return this.$store.getters.pdf_modal_show;
            }
        },
        pdfBase64()
        {
            return (
              'data:application/pdf;base64,' + this.$store.getters.pdf_modal.name
            );
        }
    }
};
</script>
<style lang="scss" scoped>
#bandeau {
    background-color: #003340;
    height: 2rem;

    .button {
        color: white;
    }
}

#footer_analyse_sheet {
    position: relative;
    padding-bottom: 1.5rem;

    h4 {
        color: black;
        font-weight: bold;
        text-align: left;
        border-bottom: 2px #58a4b0 solid;
    }

    ul {
        margin-left: 1rem;
    }
}

.truc {
    position: fixed;
    bottom: 0;
    z-index: 20;
}

.toolbar_vue {
    background-color: greenyellow;
    width: 960px;
    max-height: 400px;
    bottom: 0;

    .toolbar_vue_title {
        height: 30px;
        background-color: purple;
    }
}
</style>
