<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>Permis de construire : {{ item.depositair }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex justify-content-center">
                <div class="text-left">
                    <p class="mb-0">
                        <strong>Numéro de permis :</strong>
                        {{ item.num_pc ? item.num_pc : 'Donnée inconnue' }}
                    </p>
                    <p class="mb-0">
                        <strong>Déposé en :</strong>
                        {{ item.date_depot ? item.date_depot : 'Donnée inconnue' }}
                    </p>
                    <p class="mb-0">
                        <strong>Adresse :</strong>
                        {{ item.adresse ? item.adresse : 'Donnée inconnue' }}
                    </p>
                    <!-- <p class="mb-0"><strong>Parcelle :</strong> {{ item.num_pc }}</p> -->
                    <p class="mb-0">
                        <strong>Nombre de logements :</strong>
                        {{ verify_null_number(item.nblog) }}
                    </p>
                    <p class="mb-0">
                        <strong>Type de résidence :</strong>
                        {{ item.typere ? item.typere : 'Donnée inconnue' }}
                    </p>
                </div>
            </b-col>
            <b-col class="d-flex justify-content-center">
                <div id="pie_chart_1">
                    <apexchart
                      width="300"
                      type="pie"
                      :options="chartOptions"
                      :series="[
              parseFloat(Number(item.part_collectif).toFixed(1)),
              parseFloat(Number(item.part_individuel).toFixed(1))
            ]"
                    ></apexchart>
                </div>
            </b-col>
        </b-row>
        <p class="paragraphe">
            Répartition des typologies
        </p>
        <b-row>
            <b-col class="d-flex justify-content-center">
                <div id="pie_chart_2">
                    <apexchart
                      width="250"
                      type="pie"
                      :options="chartOptions_2"
                      :series="[
              parseInt(item.nbt1),
              parseInt(item.nbt2),
              parseInt(item.nbt3),
              parseInt(item.nbt4),
              parseInt(item.nbt5),
              parseInt(item.nbt6)
            ]"
                    ></apexchart>
                </div>
            </b-col>
            <b-col class="d-flex align-items-center">
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-thead>
                        <b-th>Studio</b-th>
                        <b-th>T2</b-th>
                        <b-th>T3</b-th>
                        <b-th>T4</b-th>
                        <b-th>T5</b-th>
                        <b-th>T6</b-th>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>{{ verify_null_number(item.nbt1) }}</b-td>
                            <b-td>{{ verify_null_number(item.nbt2) }}</b-td>
                            <b-td>{{ verify_null_number(item.nbt3) }}</b-td>
                            <b-td>{{ verify_null_number(item.nbt4) }}</b-td>
                            <b-td>{{ verify_null_number(item.nbt5) }}</b-td>
                            <b-td>{{ verify_null_number(item.nbt6) }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p class="text-muted text-left">
                    Notons que les permis de construire ci-dessus sont géolocalisés grâce
                    aux informations disponibles sur la base Sitadel.2 (adresses,
                    parcelles, etc.), cependant certaines informations peuvent venir à
                    manquer. Ainsi, malgré tout le soin apporté par INOVEFA au travail de
                    retraitement, certains permis de construire sont absents de la
                    présente carte. Les données ne sauraient être exhaustives.
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'PermisConstruire',
    data()
    {
        return {
            chartOptions: {
                labels: ['Part collectif', 'Part individuel'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val)
                    {
                        return val + '%';
                    }
                }
            },
            chartOptions_2: {
                labels: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6'],
                dataLabels: {
                    enabled: true
                }
            }
        };
    },
    methods: {
        verify_null_currency: tools.verify_null_currency,
        verify_null_number: tools.verify_null_number
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0;
    }
}

.paragraphe {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}
</style>
