<template>
    <div>
        <h5 class="flex justify-self-center pt-2 h4">
            Légende
        </h5>
        <v-divider class="mt-0"></v-divider>
        <v-list-item dense>
            <img :src="require('@/assets/dechetterie.png')" alt="icon_dechetterie"/>
            <span class="body-2">
        Déchetterie
      </span>
        </v-list-item>
        <v-list-item dense>
            <img :src="require('@/assets/site_seveso.png')" alt="icon_site_seveso"/>
            <span class="body-2">
        Site SEVESO
      </span>
        </v-list-item>
        <v-list-item dense>
            <img
              :src="require('@/assets/station_epuration.png')"
              alt="icon_station_epuration"
            />
            <span class="body-2">
        Station d'épuration
      </span>
        </v-list-item>
        <v-list-item dense>
            <img
              :src="require('@/assets/station_service.png')"
              alt="icon_station_service"
            />
            <span class="body-2">
        Station-Service
      </span>
        </v-list-item>
        <v-list-item dense>
            <img
              :src="require('@/assets/transformateur.png')"
              alt="icon_transformateur"
            />
            <span class="body-2">
        Transformateur électrique
      </span>
        </v-list-item>
    </div>
</template>

<script>
export default {
    name: 'LegendVigilance',
    data()
    {
        return {};
    }
};
</script>

<style>
img {
    width: 25px;
    margin-right: 10px;
}
</style>
