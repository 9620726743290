<template>
    <b-container b-container v-if="item" class="p-0 text-left">
        <b-row>
            <b-col col md="12">
                <h3>
                    Taxe foncière des logements de
                    {{ item.nom_com }}
                </h3>
            </b-col>
        </b-row>

        <b-row class="row_2">
            <b-col>
                <div>
                    <div class="mt-3">
                        <h5>Nombre de logements par surface</h5>
                    </div>
                    <table class="mt-0">
                        <thead class="text-dark">
                        <th>
                            Moins de 30m²
                        </th>
                        <th>De 30m² à moins de 40m²</th>
                        <th>De 40m² à moins de 60m²</th>
                        <th>De 60m² à moins de 80m²</th>
                        <th>De 80m² à moins de 100m²</th>
                        <th>De 100m² à moins de 120m²</th>
                        <th>120m² ou plus</th>
                        </thead>
                        <tbody>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_moins_30 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_30_40 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_40_60 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_60_80 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_80_100 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_100_120 }}
                        </td>
                        <td>
                            {{ this.item.selected_taxe_fonciere[0].nb_plus_120 }}
                        </td>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col col md="12">
                <div>
                    <h5>
                        Taxe foncière estimative ( déclaration 2021 - montant 2020 )
                    </h5>
                </div>
            </b-col>
            <b-col>
                <div>
                    <table>
                        <thead>
                        <th></th>
                        <th>
                            Montant en € annuel *
                        </th>
                        </thead>
                        <tbody>
                        <tr>
                            <th>T1</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t1
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>T2</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t2
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>T3</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t3
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>T4</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t4
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>T5</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t5
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>T6</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].tf_t6
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>Taxe moyenne / logement</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].taxe_logements
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>Taxe moyenne / m²</th>
                            <td>
                                {{
                                    verify_null_currency(
                                      this.item.selected_taxe_fonciere[0].taxe_mc
                                    )
                                }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
            <b-col>
                <p>Simulation approximative :</p>
                <div>
                    <div>
                        <b-input-group append="m²" class="mb-2 mr-sm-2 mb-sm-0">
                            <b-form-input
                              id="inline-form-input-taxe"
                              placeholder="Entrez la surface du logement"
                              v-model="surface"
                              type="number"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <p class="m-4 font-weight-bold text-dark">
                        Résultat de la taxe foncière :
                        {{ verify_null_currency(calcul_taxe_surface.toFixed(0)) }}
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row
        >
            <b-col
            ><p class="mt-2">
                * Ces montants sont des montants estimatifs calculés par ville en
                fonction des méthodes de calcul fournies par l'administration fiscale.
            </p></b-col
            >
        </b-row
        >
        <portal selector="#footer_analyse_sheet_list"
        >
            <li style="text-align: left;">Inovefa Expertise</li>
        </portal
        >
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'TaxeFonciere',
    data()
    {
        return {
            surface: null
        };
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        calcul_taxe_surface()
        {
            return this.item.selected_taxe_fonciere[0].taxe_mc * this.surface;
        }
    },
    components: {},
    props: {},
    watch: {},
    methods: {
        verify_null_currency: tools.verify_null_currency
    }
};
</script>

<style scoped lang="scss">

h3,
h4,
h5 {
    color: black;
    font-weight: bold;
    margin-top: 5px;
    padding: 5px;
    border-bottom: 2px solid #58a4b0;
}

.badge-pill {
    font-size: 12px;
}

.negative_value {
    color: #eb2f06;
    font-weight: bold;
}

.positive_value {
    color: #24a440;
    font-weight: bold;
}

.col_wrap {
    background-color: yellow;
}

td {
    width: 250px;
}

.child_part_1 {
    display: flex;

    height: auto;
    color: white;
    padding: 5px;

    margin: 15px 0px;

    div {
        height: 50px;
        display: table;
        text-align: center;

        p {
            vertical-align: middle;
            display: table-cell;
            margin: 0;
        }
    }

    .left_1 {
        color: black;
        font-weight: bold;
        background-color: lightblue;
        width: 275px;
    }

    .right_1 {
        color: black;
        background-color: white;
        border: 2px solid lightblue;
        min-width: 60px;
        margin-left: 10px;
        padding: 5px;
    }
}

table {
    border-collapse: separate;
    border-spacing: 2px 4px;
    font-size: 0.9em;
    height: 50px;
    text-align: center;

    thead {
        height: 75px;

        th {
            color: black;
            background-color: lightblue;
            padding: 10px;
        }
    }

    tbody {
        th {
            padding: 5px;
            color: black;
            font-weight: bold;
            background-color: lightblue;
            border: 1px solid lightgray;
        }

        td {
            color: black;
            font-weight: bold;
            background-color: white;
            border: 1px solid lightgray;
        }
    }
}

.titres {
    margin-top: 40px;
}

.graphiques_1 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    #chart {
        color: black;
    }

    .taux_vacance {
        p {
            background-color: lightblue;
            padding: 5px;
            margin-bottom: 4px;
        }

        div {
            color: black;
            border: 2px solid lightblue;
        }
    }
}
</style>
