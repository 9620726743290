var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100"},[_c('l-map',{ref:"map",staticStyle:{"height":"100%","width":"100%","z-index":"1"},attrs:{"zoom":_vm.initialZoom,"center":_vm.initialCenter,"options":{ zoomControl: false }},on:{"update:zoom":_vm.zoomUpdated,"update:center":_vm.centerUpdated,"update:bounds":_vm.boundsUpdated}},[_vm._l((_vm.tileProviders),function(tileProvider){return _c('l-tile-layer',{key:tileProvider.name,attrs:{"name":tileProvider.name,"visible":tileProvider.visible,"url":tileProvider.url,"attribution":tileProvider.attribution,"layer-type":"base"}})}),_vm._l((_vm.overlayProviders),function(overlayProvider){return _c('l-tile-layer',{key:overlayProvider.name,attrs:{"name":overlayProvider.name,"url":overlayProvider.url,"visible":overlayProvider.visible,"layer-type":"overlay"}})}),_c('l-control-layers',{attrs:{"z-index":1000,"position":"topright"}}),_c('v-overlay',{attrs:{"z-index":10000,"value":_vm.overlay_loading,"opacity":0.2}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('mygeo',{on:{"result_searching":_vm.result_searching}}),(
          _vm.data_geo.features.length != 0 &&
            _vm.data_geo.features[0].geometry.type == 'MultiPolygon' &&
            _vm.config_selected.type_geo == 'choropleth'
        )?_c('l-choropleth-layer',{attrs:{"data":_vm.data_array,"idKey":"id","value":_vm.changeValue,"geojson":_vm.data_geo,"geojsonIdKey":"id","colorScale":_vm.changeColorScale,"titleKey":"key_name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('l-info-control',{attrs:{"light":"","item":props.currentItem,"unit":props.unit,"title":"","placeholder":"Placez-vous sur une zone"}}),(_vm.config_selected.page != 'PLU')?_c('l-reference-chart',{attrs:{"title":_vm.key_name,"colorScale":_vm.changeColorScale,"min":_vm.roundUpDown(props.min),"max":_vm.roundUpDown(props.max, 'up'),"position":"topleft"}}):_vm._e()]}}],null,false,767621775)}):_vm._e(),_c('l-layer-group',{ref:"zones"},[(
            _vm.data_geo.features.length != 0 &&
              _vm.config_selected.type_geo == 'geojson_zone'
          )?_c('l-geojson',{attrs:{"geojson":_vm.new_radius(_vm.data_geo),"options":_vm.options,"options-style":_vm.styleZoneFunction}}):_vm._e()],1),_c('l-layer-group',{ref:"zones_radius"},[(
            _vm.data_geo.features.length != 0 &&
              _vm.config_selected.type_geo == 'geojson_zone'
          )?_c('l-geojson',{attrs:{"geojson":_vm.new_radius(_vm.data_geo, 300),"options":_vm.options_etendu,"options-style":_vm.styleZoneRadiusFunction,"visible":_vm.visible_radius_boolean}}):_vm._e()],1),_c('l-layer-group',{ref:"zones_radius"},[(
            _vm.data_geo.features.length != 0 &&
              _vm.config_selected.type_geo == 'geojson_zone'
          )?_c('l-geojson',{attrs:{"geojson":_vm.new_radius(_vm.data_geo, 500),"options":_vm.options_etendu,"options-style":_vm.styleZoneRadius500Function,"visible":_vm.visible_radius_boolean}}):_vm._e()],1),_c('l-layer-group',{ref:"markerCluster"},[(
            _vm.data_geo.features.length != 0 && _vm.config_selected.type_geo == 'point'
          )?_c('l-geojson',{attrs:{"geojson":_vm.data_geo,"visible":false}}):_vm._e()],1),_c('l-layer-group',{ref:"markerCluster"},[(
            _vm.data_geo.features.length != 0 &&
              _vm.config_selected.type_geo == 'multi_geo'
          )?_c('l-geojson',{attrs:{"geojson":_vm.data_geo,"options":_vm.options,"options-style":_vm.styleZoneFunction,"visible":false}}):_vm._e()],1),(_vm.config_selected.show_filter == true)?_c('l-control',{attrs:{"position":"topleft"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"x-large":"","color":"#be1a3e"}},'v-btn',attrs,false),on),[_vm._v(" Filtrer ")])]}}],null,false,3429804016),model:{value:(_vm.filter_checked),callback:function ($$v) {_vm.filter_checked=$$v},expression:"filter_checked"}},[_c('v-card',{staticClass:"hud_overlay_filtre"},[(_vm.config_selected.page == 'transports')?_c('filtre-transports',{on:{"filter_component":function($event){_vm.filter_selected = $event}}}):_vm._e(),(_vm.config_selected.page == 'points_interets')?_c('filtre-point-interet',{on:{"filter_component":function($event){_vm.filter_selected = $event}}}):_vm._e(),(_vm.config_selected.page == 'zone_tva')?_c('filtre-zone-tva'):_vm._e(),(_vm.config_selected.page == 'operation_amen')?_c('filtre-operation-amen',{on:{"filter_component":function($event){_vm.filter_selected = $event}}}):_vm._e(),(_vm.config_selected.page == 'commerce')?_c('filtre-commerces'):_vm._e(),(_vm.config_selected.page == 'permis_construire')?_c('filtre-permis-construire'):_vm._e(),(_vm.config_selected.page == 'marche_ancien_geoloc' || _vm.config_selected.page == 'marche_neuf_geoloc')?_c('filtre-transactions-geolocalisees'):_vm._e()],1)],1)],1):_vm._e(),_c('l-control',{attrs:{"position":"topright"}},[_c('v-card',{staticClass:"hud_overlay_legend"},[(_vm.config_selected.page == 'zone_tva')?_c('legend-zone-tva'):_vm._e(),(_vm.config_selected.page == 'zone_peb')?_c('legend-zone-peb'):_vm._e(),(_vm.config_selected.page == 'operation_amen')?_c('legend-operation-amen'):_vm._e(),(_vm.config_selected.page == 'etablissements_scolaires')?_c('legend-scolaire'):_vm._e(),(_vm.config_selected.page == 'points_vigilance')?_c('legend-vigilance'):_vm._e()],1)],1),_c('l-control-zoom',{attrs:{"position":"bottomright"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }