<template>
    <b-container v-if="item">
        <b-row>
            <b-col col md="6">
                <h3>
                    Informations sur le propriétaire
                </h3>
            </b-col>
            <b-col col md="6">
                <h3>
                    Informations sur l'immeuble
                </h3>
            </b-col>
        </b-row>

        <b-row class="row_2">
            <b-col col md="6">
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Nom du bailleur social</p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{
                                item.denomination_proprietaire
                                  ? item.denomination_proprietaire
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Parcelle</p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{ item.parcelle_id }}
                        </p>
                    </div>
                </div>

                <div class="child_part_1">
                    <div class="left_1">
                        <p>Contenance (SUF)</p>
                    </div>
                    <div class="right_1">
                        <p>{{ item.contenance_suf }}</p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Signification du code du droit</p>
                    </div>
                    <div class="right_1">
                        <p>{{ item.signification_code_droit }}</p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Groupe de la personne morale</p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{
                                item.groupe_personne_morale_proprietaire
                                  ? item.groupe_personne_morale_proprietaire
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col col md="6">
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Droit appartement</p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{ item.droit_appartement ? item.droit_appartement : 'Inconnu' }}
                        </p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>Origine du patrimoine</p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{
                                item.origine_patrimoine ? item.origine_patrimoine : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>
                            Achévement construction
                        </p>
                    </div>
                    <br/>
                    <div class="right_1">
                        <p>
                            {{
                                item.annee_fin_construction
                                  ? item.annee_fin_construction
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="left_1">
                        <p>
                            Mise en location
                        </p>
                    </div>
                    <br/>
                    <div class="right_1">
                        <p>
                            {{
                                item.annee_location_logement
                                  ? item.annee_location_logement
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
                <div class="child_part_1">
                    <div class="right_1">
                        <p>
                            {{
                                item.logement_quartier_prioritaire_ville
                                  ? item.logement_quartier_prioritaire_ville
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                    <div class="right_1">
                        <p>
                            {{
                                item.code_type_construction
                                  ? item.code_type_construction
                                  : 'Inconnu'
                            }}
                        </p>
                    </div>
                </div>
            </b-col>

            <b-col col md="12">
                <b-row>
                    <b-col col md="12">
                        <h3>
                            Répartition par typologie et SHAB moyenne
                        </h3>
                    </b-col>
                </b-row>
                <table class="table_main">
                    <thead>
                    <th>T1</th>
                    <th>T2</th>
                    <th>T3</th>
                    <th>T4</th>
                    <th>T5</th>
                    <th>T6 et +</th>
                    <th>Pièces moyennes</th>
                    <th>SHAB moyenne</th>
                    </thead>
                    <tbody>
                    <td>
                        {{ item.nombre_studio ? item.nombre_studio : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.nombre_T2 ? item.nombre_T2 : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.nombre_T3 ? item.nombre_T3 : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.nombre_T4 ? item.nombre_T4 : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.nombre_T5 ? item.nombre_T5 : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.nombre_T6_plus ? item.nombre_T6_plus : 'Inconnu' }}
                    </td>

                    <td>
                        {{
                            item.nombre_piece_moyen
                              ? parseInt(item.nombre_piece_moyen).toFixed(0)
                              : 'Inconnu'
                        }}
                    </td>

                    <td>
                        {{
                            item.surface_habitable_moyen
                              ? parseInt(item.surface_habitable_moyen).toFixed(0)
                              : 'Inconnu'
                        }}
                    </td>
                    </tbody>
                </table>
            </b-col>
            <b-col col md="12">
                <b-row>
                    <b-col col md="12">
                        <h3>
                            Nombre de logements dont le DPE est ...
                        </h3>
                    </b-col>
                </b-row>
                <table class="table_main">
                    <thead>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>F</th>
                    <th>G</th>
                    <th>NC</th>
                    </thead>
                    <tbody>
                    <td>
                        {{ item.A ? item.A : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.B ? item.B : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.C ? item.C : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.D ? item.D : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.E ? item.E : 'Inconnu' }}
                    </td>
                    <td>
                        {{ item.F ? item.F : 'Inconnu' }}
                    </td>

                    <td>
                        {{ item.G ? item.G : 'Inconnu' }}
                    </td>

                    <td>
                        {{ item.nc ? item.nc : 'Inconnu' }}
                    </td>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'LogementSocial',
    mounted()
    {
    },
    data()
    {
        return {};
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    },
    methods: {
        verify_null_number: tools.verify_null_number
    }
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

h3,
h4,
h5 {
    color: black;
    font-weight: bold;
    margin-top: 5px;
    padding: 5px;
    border-bottom: 2px solid #58a4b0;
}

//   h3 {
//     text-align: center;
//     padding: 5px;
//     background-color: #9abeca;
//     color: #fff;
//   }

.badge-pill {
    font-size: 12px;
}

.negative_value {
    color: #eb2f06;
    font-weight: bold;
}

.positive_value {
    color: #24a440;
    font-weight: bold;
}

.col_wrap {
    background-color: yellow;
}

td {
    width: 250px;
}

.child_part_1 {
    display: flex;

    height: auto;
    color: white;
    padding: 5px;

    margin: 15px 0px;

    div {
        height: 50px;
        display: table;
        text-align: center;

        p {
            vertical-align: middle;
            display: table-cell;
            margin: 0;
        }
    }

    .left_1 {
        color: $C_expertise;
        font-weight: bold;
        background-color: $C_expertise_alt;
        width: 275px;
    }

    .right_1 {
        color: black;
        background-color: white;
        min-width: 60px;
        margin-left: 10px;
        padding: 5px;
    }
}

.table_main {
    border-collapse: separate;
    border-spacing: 2px 4px;
    font-size: 0.9em;
    height: 50px;
    text-align: center;

    thead {
        th {
            color: $C_expertise;
            background-color: $C_expertise_alt;
            padding: 5px;
        }
    }

    tbody {
        td {
            color: black;
            font-weight: bold;
            background-color: white;
            border: 1px solid lightgray;
        }
    }
}

.titres {
    margin-top: 40px;
}

.graphiques_1 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    #chart {
        // width: 100px;
        // height: 40px;
        // background-color: lightgoldenrodyellow;
        color: black;
    }

    .taux_vacance {
        p {
            background-color: $C_lisght_blue;
            padding: 5px;
            margin-bottom: 4px;
        }

        div {
            color: black;
            border: 2px solid $C_expertise_light;
        }
    }
}
</style>
