<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>Préconisations INOVEFA pour {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="qualite" md="8">
                <p class="h5 font-weight-bold">Commentaire</p>
                <p class="contenu body-1">
                    {{ item.selected_preco_iris[0].commentaire }}
                </p>
            </b-col>
            <b-col class="potentiel" md="4">
                <div class="mb-3">
                    <p class="h5 font-weight-bold mb-2">Potentiel investisseur</p>
                    <span class="contenu body-1">{{ text_investisseur() }}</span>
                </div>
                <div>
                    <p class="h5 font-weight-bold mb-2">Prestation</p>
                    <span class="contenu body-1">{{ text_prestation() }}</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="6">
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>
                                Prix
                            </b-th>
                            <b-th>
                                BAS
                            </b-th>
                            <b-th>
                                HAUT
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>Projet non éligible TVA Réduite (TVA 20%)</b-td>
                            <b-td v-if="item.selected_preco_iris[0].prix_bas > 1">{{
                                    verify_null_currency(item.selected_preco_iris[0].prix_bas)
                                }}
                            </b-td>
                            <b-td v-else>Indéfini</b-td>
                            <b-td v-if="item.selected_preco_iris[0].prix_haut > 1">{{
                                    verify_null_currency(item.selected_preco_iris[0].prix_haut)
                                }}
                            </b-td>
                            <b-td v-else>Indéfini</b-td>
                        </b-tr>
                        <b-tr
                          v-if="
                item.selected_preco_iris[0].prix_haut_tva !== 0 ||
                  item.selected_preco_iris[0].prix_bas_tva !== 0
              "
                        >
                            <b-td>Projet éligible TVA Réduite (TVA 20%)</b-td>
                            <b-td v-if="item.selected_preco_iris[0].prix_bas_tva !== 1"
                            >{{
                                    verify_null_currency(item.selected_preco_iris[0].prix_bas_tva)
                                }}
                            </b-td>
                            <b-td v-else>Indéfini</b-td>
                            <b-td v-if="item.selected_preco_iris[0].prix_haut_tva !== 1"
                            >{{
                                    verify_null_currency(
                                      item.selected_preco_iris[0].prix_haut_tva
                                    )
                                }}
                            </b-td>
                            <b-td v-else>Indéfini</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col col md="6">
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>
                                SHAB moyen
                            </b-th>
                            <b-th>
                                BAS
                            </b-th>
                            <b-th>
                                HAUT
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>{{ item.nom_iris }}</b-td>
                            <b-td>{{ item.selected_preco_iris[0].shab_bas }} m²</b-td>
                            <b-td>{{ item.selected_preco_iris[0].shab_haut }} m²</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row></b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_currency} from './functions_utilities';

export default {
    name: 'PrecoIris',
    data()
    {
        return {};
    },
    methods: {
        verify_null_currency,
        text_prestation()
        {
            switch (this.item.selected_preco_iris[0].prestation)
            {
                case 0:
                    return 'Inconnu';
                case 1:
                    return 'Entrée de gamme';
                case 2:
                    return 'Milieu de gamme';
                case 3:
                    return 'Haut de gamme';
                default:
                    break;
            }
        },
        text_investisseur()
        {
            switch (this.item.selected_preco_iris[0].invest_potentiel)
            {
                case '0':
                    return 'Inconnu';
                case '1':
                    return 'Faible';
                case '2':
                    return 'Moyen';
                case '3':
                    return 'Fort';
                default:
                    break;
            }
        }
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
