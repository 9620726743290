<template>
    <div>
        <h5 class="flex justify-self-center pt-2 h4">Légende</h5>
        <v-divider class="mt-0"></v-divider>
        <v-list-item dense>
            <div class="square" style="background-color: #ff0000;"/>
            <span class="body-2">: Zone A</span>
        </v-list-item>
        <v-list-item dense>
            <div class="square" style="background-color: #fc8800;"/>
            <span class="body-2">: Zone B</span>
        </v-list-item>
        <v-list-item dense>
            <div class="square" style="background-color: #2DD2FC;"/>
            <span class="body-2">: Zone C</span>
        </v-list-item>
        <v-list-item dense>
            <div class="square" style="background-color: #b0ffa6;"/>
            <span class="body-2">: Zone D</span>
        </v-list-item>
    </div>
</template>

<script>
export default {};
</script>

<style>
.square {
    width: 15px;
    height: 15px;
    border-radius: 25px;
    margin-right: 1em;
}
</style>
