<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>{{ item.nom_quartier }}</h4>
            </b-col>
            <b-col col md="12">
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-tr>
                        <b-th>Type de zone</b-th>
                        <b-td>{{ item.zone }}</b-td>
                    </b-tr>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'ZonePeb',
    data()
    {
        return {};
    },
    methods: {},
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}
</style>
