<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>{{ item.nom_quartier }}</h4>
            </b-col>
            <b-col col md="12">
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-tr>
                        <b-th>Type de zone</b-th>
                        <b-td>{{ item.type }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Éligibilité TVA 5.5%</b-th>
                        <b-td>{{ item.tva_55 === 1 ? 'Oui' : 'Non' }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Numéro</b-th>
                        <b-td>{{
                                item.n_qpv ? item.n_qpv : item.zus ? item.zus : item.art6
                            }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Extensible</b-th>
                        <b-td>{{
                                item.tva_55 === 0
                                  ? '-'
                                  : item.eligibilite === "O"
                                    ? 'de 300 à 500 mètres'
                                    : '300 mètres'
                            }}
                        </b-td>
                    </b-tr>
                </b-table-simple>
                <p>
                    * Attention, il sera nécessaire de valider l’éligibilité à la TVA
                    réduite d’un projet donné par une analyse parcellaire précise,
                    confirmée par un rescrit fiscal auprès du service des impôts
                    territorialement compétent.
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'ZoneTva',
    data()
    {
        return {};
    },
    methods: {},
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

#graph {
    .titre_graph {
        width: 100%;
        border-bottom: 2px #58a4b0 solid;

        p {
            margin: 0;
            float: left;
            font-weight: bold;
        }

        span {
            float: right;
            font-weight: bold;
        }
    }
}
</style>
