export let verify_null_currency = (number, typo = '') =>
{
    if (number)
    {
        number = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
        }).format(number);
        return number + typo;
    }
    else
        return 'Donnée inconnue'
}

export let verify_null_number = (number, typo = '') =>
{
    if (number || number === 0)
    {
        number = new Intl.NumberFormat('fr-FR', {
            style: 'decimal'
        }).format(number);
        if (typo !== '')
            return number + ' ' + typo;
        else
            return number;
    }
    else
        return 'Donnée inconnue'
}

export let verify_null_percent = (number, fractionDigit = 0, bool_sign = false) =>
{
    if (number || number === 0)
    {
        number = new Intl.NumberFormat('fr-FR', {
            style: 'percent',
            maximumFractionDigits: fractionDigit
        }).format(number);

        if (bool_sign)
        {
            let splitted = number.split('%');
            let sign = Math.sign(parseFloat(splitted[0].replace(',', '.')));
            switch (sign)
            {
                case -1:
                    return number;
                case 0:
                    return number;
                case 1:
                    return '+' + number;
                default:
                    return 'Inconnu';
            }
        }
        else
            return number;
    }
    else
        return 'Donnée inconnue'
}

export let color_evolution = number =>
{
    let splitted = number.split('%');
    let sign = Math.sign(parseFloat(splitted[0]));
    switch (sign)
    {
        case -1:
            return 'color:red;';
        case 0:
            return 'color:grey;';
        case 1:
            return 'color:green;';
        default:
            return 'color:grey;';
    }
}
