<template>
    <div class="h-100 menu">
        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <img
                      class="content"
                      src="../assets/EDII_white.png"
                      alt="Logo Edii"
                      style="width: 80%; height: auto;"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><span style="color:white">Menu</span></div>
                <v-list-item @click="logout()">Déconnexion</v-list-item>
            </v-list>
        </v-menu>

        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="users"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><p>Population</p></div>
                <v-list-item @click="searchitem('com', 'evo_pop_com')"
                >Évolution de la population
                </v-list-item
                >
                <v-list-item @click="searchitem('com', 'struct_pop_com')"
                >Structure de la population
                </v-list-item
                >
                <v-list-item @click="searchitem('iris', 'cap_achat')"
                >Capacité achat au m²
                </v-list-item
                >
                <v-list-item @click="searchitem('iris', 'gentrification')"
                >Gentrification
                </v-list-item
                >
            </v-list>
        </v-menu>
        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="train"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><p>Services</p></div>
                <v-list-item @click="searchMarker('commerce')"> Commerces</v-list-item>
                <v-list-item @click="searchMarker('transports')">
                    Transports
                </v-list-item
                >
                <v-list-item @click="searchMarker('etablissements_scolaires')">
                    Équipements scolaires
                </v-list-item
                >
                <v-list-item @click="searchMarker('points_interets')">
                    Points d'intérêt
                </v-list-item
                >
                <v-list-item @click="searchMarker('points_vigilance')">
                    Points de vigilance
                </v-list-item
                >
                <v-list-item @click="searchMarker('couverture_fibre')"
                >Couverture fibre
                </v-list-item
                >
            </v-list>
        </v-menu>
        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="home"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><p>Données immobilières</p></div>
                <v-list-item @click="searchitem('com', 'parc_immo_com')"
                >Parc Immobilier
                </v-list-item
                >
                <v-list-item @click="searchitem(null, 'zone_tva')"
                >Zones d'éligibilité TVA Réduite
                </v-list-item
                >
                <v-list-item @click="searchMarker('PLU')"
                >Données PLU
                </v-list-item
                >
                <v-list-item @click="searchMarker('permis_construire')"
                >Permis de constuire
                </v-list-item
                >
                <v-list-item @click="searchitem('com', 'parc_social')"
                >Composition du parc social
                </v-list-item
                >
                <v-list-item @click="searchMarker('logements_sociaux')"
                >Géolocalisation des résidences sociales
                </v-list-item
                >
                <v-list-item @click="searchitem(null, 'operation_amen')"
                >Opérations d'aménagements
                </v-list-item
                >

                <v-list-item @click="searchitem(null, 'zone_peb')"
                >Plan d'exposition au bruit
                </v-list-item
                >
            </v-list>
        </v-menu>
        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="euro-sign"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><p>Données de prix</p></div>
                <v-list-item @click="searchitem('com', 'preco_com')"
                >Prix - Préconisations INOVEFA Expertise
                </v-list-item
                >
                <v-list-item @click="searchMarker('marche_neuf_geoloc')"
                >Prix - Transactions actées dans le neuf
                </v-list-item
                >
                <v-list-item @click="searchMarker('programmes_neufs')"
                >Prix - Programmes neufs en cours
                </v-list-item
                >
                <v-list-item @click="searchMarker('marche_ancien_geoloc')"
                >Prix - Transactions actées dans l'ancien
                </v-list-item
                >
                <v-list-item @click="searchitem('com', 'marche_ancien')"
                >Prix - Données générales de la transaction
                </v-list-item
                >
                <v-list-item @click="searchitem('iris', 'marche_locatif')"
                >Locatif - Marchés et niveaux de loyers
                </v-list-item
                >
            </v-list>
        </v-menu>
        <v-menu
          z-index="3000"
          right
          offset-x
          transition="slide-x-transition"
          attach=".menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  v-bind="attrs"
                  v-on="on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="toolbox"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <v-list style="padding-top:0;">
                <div class="dropdown-header"><p>Outils</p></div>
                <v-list-item @click="searchMarker('pinel')">
                    Simulateur Pinel
                </v-list-item
                >
                <v-list-item @click="openNoData('mensualite')">
                    Mensualités
                </v-list-item
                >
                <v-list-item @click="searchMarker('menage_solvable')">
                    Ménages solvables
                </v-list-item
                >
                <v-list-item @click="searchitem(null, 'taxes_foncieres')"
                >Taxes foncières
                </v-list-item
                >
            </v-list>
        </v-menu>

        <v-tooltip right color="warning">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  active-class="activeButtonMenu"
                  class="buttonMenu"
                  @click="actionPDF()"
                  v-bind="attrs"
                  v-on="tooltip_on ? null : on"
                >
                    <font-awesome-icon
                      class="content"
                      icon="file-alt"
                      :style="{ color: 'white' }"
                    />
                </v-btn>
            </template>
            <span>Vous devez sélectionner une adresse pour télécharger le rapport PDF.</span>
        </v-tooltip>
    </div>
</template>

<script>

export default {
    name: 'Sidebar',
    data()
    {
        return {
            authorize_role: false
        };
    },
    created()
    {
        let jwt = this.$cookies.get('authcookie').split('.');
        let token_role = Buffer.from(jwt[1], 'base64');
        switch (JSON.parse(token_role.toString()).role)
        {
            case 'admin':
            case 'moderator':
                this.authorize_role = true;
                break;

            default:
                break;
        }
    },
    methods: {
        actionPDF()
        {
            this.$store.getters.get_marker_location
              ? this.$store.dispatch('actionPdf')
              : null;
        },
        logout()
        {
            this.$cookies.remove('authcookie');
            this.$router.push('/log');
        },
        pushTo(page)
        {
            this.$router.push(page);
        },
        searchitem(zone, page)
        {
            this.$store.commit('switchReset');
            this.$store.commit('mutateConfig_selected', page);
        },
        searchMarker(page)
        {
            this.$store.commit('switchReset');
            this.$store.commit('mutateConfig_selected', page);
        },
        openNoData(page)
        {
            this.$emit('open_instant', true);
            this.$store.commit('switchReset');
            this.$store.commit('mutateConfig_selected', page);
        }
    },
    computed: {
        tooltip_on()
        {
            return this.$store.getters.get_marker_location;
        }
    }
};
</script>

<style lang="scss">
.dropdown-header {
    padding: 5px;
    background-color: #ce0538;
    width: 100%;
    font-size: 1.3em;

    p {
        margin-bottom: 0;
        color: white;
        font-weight: bold;
    }
}

.button_menu {
    position: relative;
    width: 90%;
    background-color: transparent;

    &:hover {
        border-radius: 5px;
        background-color: #ce0538;
    }

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    .content {
        width: 55%;
        height: 55%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.v-list-item {
    font-size: 1.3rem;
}

.buttonMenu.theme--light.v-btn {
    // margin-right: 5px;
    position: relative;
    min-width: 90%;
    height: auto;
    background-color: transparent;
    flex: none;
    padding: 0;

    &:hover {
        border-radius: 5px;
        background-color: #ce0538;
    }

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    .content {
        position: absolute;
        // width: 100%;
        // height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.7em;
    }
}

.menu .v-menu__content {
    margin-left: 10px;
}

.activeButtonMenu.theme--light.v-btn.v-btn--has-bg {
    background-color: #ce0538;
}

.menu {
    background-color: #003340;
    width: 60px;

    ul {
        padding: 0;
        list-style: none;
    }
}
</style>
