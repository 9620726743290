<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>{{ item.nom_com }}</h4>
            </b-col>
            <b-row col md="12">
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-tr>
                        <b-th>Département</b-th>
                        <b-th>Code Postal</b-th>
                        <b-th>Couverture fibre</b-th>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ item.nom_departement }}</b-td>
                        <b-td>{{ item.code_postal }}</b-td>
                        <b-td v-if="item.selected_couverture_fibre[0].couv === 5">Moins de 10% de locaux raccordables
                        </b-td>
                        <b-td v-else-if="item.selected_couverture_fibre[0].couv === 10">De 10% à 25% de locaux
                            raccordables
                        </b-td>
                        <b-td v-else-if="item.selected_couverture_fibre[0].couv === 25">De 25% à 50% de locaux
                            raccordables
                        </b-td>
                        <b-td v-else-if="item.selected_couverture_fibre[0].couv === 50">De 50% à 80% de locaux
                            raccordables
                        </b-td>
                        <b-td v-else-if="item.selected_couverture_fibre[0].couv === 80">Plus de 80% de locaux
                            raccordables
                        </b-td>
                        <b-td v-else>Pas de données disponibles</b-td>
                    </b-tr>
                </b-table-simple>
            </b-row>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'CouvertureFibre',
    data()
    {
        return {};
    },
    methods: {},
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
