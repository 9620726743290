<template>
    <div class="w-100">
        <b-row id="titre" class="w-100">
            <b-col col md="12">
                <h3>Simulateur de revenus sur une location courte durée.</h3>
                <div class="w-100">
                    <iframe
                      id="airdna-rentalizer"
                      height="100%"
                      width="100%"
                      src="https://rentalizer.airdna.co/widget/fr"
                    ></iframe>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import * as tools from './functions_utilities';

export default {
    name: 'Mensualite',
    data()
    {
        return {
            verify_null_currency: tools.verify_null_currency,
            typo: 'ancien',
            montant: 250000,
            apport: 20000,
            travaux: 50000,
            typo_values: [
                {value: 'ancien', text: "Achat dans l'ancien"},
                {value: 'neuf', text: 'Achat dans le neuf'}
            ],
            taeg: {
                '10': 1.0,
                '15': 1.25,
                '20': 1.5,
                '25': 1.6
            },
            nb_mois: {
                '10': 120,
                '15': 180,
                '20': 240,
                '25': 300
            },
            duree: '10',
            duree_marks: {
                '10': '10 ans',
                '15': '15 ans',
                '20': '20 ans',
                '25': '25 ans'
            }
        };
    },
    computed: {
        frais_de_notaire()
        {
            return this.typo === 'ancien'
              ? parseInt(this.montant) * 0.08
              : parseInt(this.montant) * 0.025;
        },
        emprunt()
        {
            return (
              parseInt(this.montant) +
              this.frais_de_notaire -
              parseInt(this.apport) +
              parseInt(this.travaux)
            );
        },
        mensualites()
        {
            return (
              this.VPM(
                this.taeg[this.duree] / 100 / 12,
                this.nb_mois[this.duree],
                this.emprunt
              ) * -1
            );
        }
    },
    methods: {
        VPM(tx, npm, va, vc = 0, typeRembt = 0)
        {
            if (
              typeof tx != 'number' ||
              typeof npm != 'number' ||
              typeof va != 'number' ||
              typeof vc != 'number' ||
              typeof typeRembt != 'number'
            )
                return false;

            if (typeRembt > 1 || typeRembt < 0) return false;

            let txActuariel = Math.pow(1 + tx, -npm);

            if (1 - txActuariel === 0) return 0;

            let vpm =
              ((va + vc * txActuariel) * tx) /
              (1 - txActuariel) /
              (1 + tx * typeRembt);
            return -vpm;
        }
    }
};
</script>

<style lang="scss">
#titre {
    h3 {
        background-color: #58a4b0;
        color: #fff;
    }

    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
