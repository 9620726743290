<template>
    <div>
        <b-container v-if="isLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-container>
        <div v-if="dataPlu">
            <b-container v-for="(data, index) in dataPlu" :key="index">
                <b-row id="titre">
                    <b-col col md="9">
                        <h4>{{ data.title }}</h4>
                    </b-col>
                </b-row>
                <b-button v-b-toggle="'collapse-'+index" variant="primary"><span style="color: white;">Afficher</span>
                </b-button>
                <b-collapse :id="'collapse-'+index" class="mt-2">
                    <b-card>
                        <b-row v-for="(data, name, index) in data.writingMaterials" :key="index">
                            <b-col>
                                <a :href="data" target="_blank">{{ name }}</a>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-collapse>

            </b-container>
        </div>

        <div v-if="!isLoading && dataPlu.length === 0">
            <b-container>
                <b-row id="titre">
                    <b-col col md="9">
                        <h4>Données non disponibles</h4>
                    </b-col>
                </b-row>
                <b-row>Aucune donnée n'a été trouvée.</b-row>
            </b-container>
        </div>

    </div>

</template>

<script>
import axios from 'axios';

import {mapGetters} from 'vuex';

export default {
    name: 'PLU',
    data()
    {
        return {
            isLoading: true,
        };
    },
    methods: {},
    computed: {
        ...mapGetters({
            item: 'getSelected_item'
        }),
    },
    asyncComputed: {
        async dataPlu()
        {
            var com_insee = this.item.insee_com;
            var comGeo = '';
            var comFeatures = '';
            var comPluLinks = [];
            var id = '';

            comGeo = await axios.get(
              `https://apicarto.ign.fr/api/gpu/municipality?insee=${com_insee}`
            ).then(response => comGeo = response.data);

            comGeo = comGeo.features[0].geometry;

            comFeatures = await axios.get(
              `https://apicarto.ign.fr/api/gpu/document?geom=${JSON.stringify(comGeo)}`
            ).then(response => comFeatures = response.data.features);

            comFeatures.forEach(element =>
            {
                id = element.properties.id;

                if (element.properties.grid_name === com_insee)
                {
                    axios.get(
                      `https://www.geoportail-urbanisme.gouv.fr/api/document/${id}/details`
                    ).then(response => comPluLinks.push(response.data));
                }
                else if (com_insee.toString().startsWith('75') && element.properties.grid_name.startsWith('75'))
                {
                    axios.get(
                      `https://www.geoportail-urbanisme.gouv.fr/api/document/${id}/details`
                    ).then(response => comPluLinks.push(response.data));
                }
                if (element.properties.du_type === 'PLUi')
                {
                    axios.get(
                      `https://www.geoportail-urbanisme.gouv.fr/api/document/${id}/details`
                    ).then(response => comPluLinks.push(response.data));
                }
            });

            this.isLoading = false;
            return comPluLinks;
        }
    },
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
