<template>
    <div>
        <v-list-item class="m-0">
            <p class="mb-0 mt-2" style="text-align:left;">
                Type de commerce
            </p>
        </v-list-item>
        <v-list-item class="m-0">
            <v-divider></v-divider>
        </v-list-item>
        <v-list-item>
            <b-form-checkbox-group
              style="text-align: left;"
              id="btnradios3"
              stacked
              @click.native="addMoreFilter($event)"
              button-variant="outline-secondary"
              v-model="getFilter"
              :options="keyOptions"
              name="radioBtnStacked"
            />
        </v-list-item>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'FiltreCommerces',
    data()
    {
        return {
            keyOptions: [
                {text: 'Banque', value: 'bank'},
                {text: 'Restaurant', value: 'restaurant'},
                {text: 'Poste', value: 'post_office'},
                {text: 'Pharmacie', value: 'pharmacy'},
                {text: 'Bar/Café/Tabac', value: 'bar_cafe_tabac'},
                {text: 'Supermarché', value: 'supermarket'}
            ],
            filter_selected: [
                'bank',
                'restaurant',
                'post_office',
                'pharmacy',
                'bar_cafe_tabac',
                'supermarket',
                'fast_food',
                'mall',
                'general'
            ]
        };
    },
    mounted()
    {
        if (this.saved_filter.length === 0)
        {
            this.$store.commit('mutateFilter', this.filter_selected);
        }
    },
    watch: {
        filter_selected(newValue, oldValue)
        {
            this.$store.commit('mutateFilter', newValue);
        }
    },
    computed: {
        ...mapGetters({saved_filter: 'getFilter'}),
        getFilter: {
            get()
            {
                return this.saved_filter;
            },
            set(newSaved_filter)
            {
                this.filter_selected = newSaved_filter;
            }
        }
    },
    methods: {
        addMoreFilter(event)
        {
            let filter_item_1 = 'mall';
            let filter_item_2 = 'general';
            let filter_item_3 = 'fast_food';
            switch (event.target._value)
            {
                case 'restaurant':
                    if (this.filter_selected.includes(event.target._value))
                    {
                        this.filter_selected.splice(
                          this.filter_selected.indexOf(filter_item_3),
                          1
                        );
                    }
                    else
                    {
                        this.filter_selected.push(filter_item_3);
                    }
                    break;
                case 'supermarket':
                    if (this.filter_selected.includes(event.target._value))
                    {
                        this.filter_selected.splice(
                          this.filter_selected.indexOf(filter_item_2),
                          1
                        );
                        this.filter_selected.splice(
                          this.filter_selected.indexOf(filter_item_1),
                          1
                        );
                    }
                    else
                    {
                        this.filter_selected.push(filter_item_2);
                        this.filter_selected.push(filter_item_1);
                    }
                    break;
                default:
                    break;
            }
        }
    }
};
</script>

<style></style>
