<template>
    <b-container v-if="item != null && annee != null">
        <b-row id="titre">
            <b-col
            ><h4>Logements à {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="12">
                <apexchart
                  type="line"
                  height="300"
                  :series="evoLineSeries"
                  :options="Options"
                ></apexchart>
            </b-col>
            <b-col col md="6">
                <div class="truc">
                    <p>Nombre de logements ({{ annee }})</p>
                    <span>{{ verify_null_number(searchInItem.log) }}</span>
                </div>
                <div class="truc">
                    <p>Résidence principale</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_p) }})</span
                    >
                    <span>{{
                            verify_null_percent(searchInItem.res_p / searchInItem.log, 1)
                        }}</span>
                </div>
                <div class="truc">
                    <p>Type maison</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_p_maison) }})</span
                    >
                    <span>{{
                            verify_null_percent(
                              searchInItem.res_p_maison /
                              (searchInItem.res_p_maison + searchInItem.res_p_appart),
                              1
                            )
                        }}</span>
                </div>
                <div class="truc">
                    <p>Type appartement</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_p_appart) }})</span
                    >
                    <span>{{
                            verify_null_percent(
                              searchInItem.res_p_appart /
                              (searchInItem.res_p_maison + searchInItem.res_p_appart),
                              1
                            )
                        }}</span>
                </div>
            </b-col>
            <b-col col md="6">
                <div class="truc">
                    <p>Résidence occupées propriétaires</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_p_prop) }})</span
                    >
                    <span>{{
                            verify_null_percent(
                              searchInItem.res_p_prop /
                              (searchInItem.res_p_prop + searchInItem.res_loc),
                              1
                            )
                        }}</span>
                </div>
                <div class="truc">
                    <p>Résidence occupées locataire</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_loc) }})</span
                    >
                    <span>{{
                            verify_null_percent(
                              searchInItem.res_loc /
                              (searchInItem.res_p_prop + searchInItem.res_loc),
                              1
                            )
                        }}</span>
                </div>
                <div class="truc">
                    <p>Logements sociaux</p>

                    <span class="ml-2"
                    >({{ verify_null_number(searchInItem.res_p_loc_hlm_vide) }})</span
                    >
                    <span>{{
                            verify_null_percent(
                              searchInItem.res_p_loc_hlm_vide / searchInItem.log,
                              1
                            )
                        }}</span>
                </div>
                <div class="truc">
                    <p>Taux de vacance</p>
                    <span>{{
                            verify_null_percent(searchInItem.log_vac / searchInItem.log, 1)
                        }}</span>
                </div>
            </b-col>
        </b-row>
        <b-row id="titre">
            <b-col
            ><h4>Ménages à {{ item.nom_iris }}</h4></b-col
            >
        </b-row>
        <b-row>
            <b-col>
                <apexchart
                  type="line"
                  height="200"
                  :series="evoLineSeries_1"
                  :options="Options_1"
                ></apexchart
                >
            </b-col>
            <b-col>
                <div class="titre_second"><p>Durée d'emménagement</p></div>
                <div class="mt-3">
                    <div class="truc">
                        <p>moins de 2 ans</p>
                        <span class="font-weight-bold"
                        >{{ verify_null_number(searchInItem.men_moins_2) }} ({{
                                evolution_menage('men_moins_2', true)
                            }}
              )</span
                        >
                    </div>
                    <div class="truc">
                        <p>de 2 à 4 ans</p>
                        <span class="font-weight-bold"
                        >{{ verify_null_number(searchInItem.men_2_a_4) }} ({{
                                evolution_menage('men_2_a_4', true)
                            }}
              )</span
                        >
                    </div>
                    <div class="truc">
                        <p>de 5 à 9 ans</p>
                        <span class="font-weight-bold"
                        >{{ verify_null_number(searchInItem.men_5_a_9) }} ({{
                                evolution_menage('men_5_a_9', true)
                            }}
              )</span
                        >
                    </div>
                    <div class="truc">
                        <p>10 ans ou plus</p>
                        <span class="font-weight-bold"
                        >{{ verify_null_number(searchInItem.men_plus_10) }} ({{
                                evolution_menage('men_plus_10', true)
                            }}
              )</span
                        >
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_number, verify_null_percent} from './functions_utilities';

export default {
    name: 'ParcImmo',
    data()
    {
        return {
            annee: null,
            annee_tab: [],
            pop_iris_array: [],
            test: [this.pop_iris_array],
            logement_iris: [],
            logement_com: [],
            menages_iris: [],
            menages_duree: [],
            menages_additions: {
                men_moins_2: [],
                men_2_a_4: [],
                men_5_a_9: [],
                men_plus_10: []
            }
        };
    },
    methods: {
        verify_null_number,
        verify_null_percent,
        evolution_iris: function (item, index)
        {
            let last = item;
            let before = this.logement_iris[index - 1];
            return verify_null_percent(
              ((last - before) / before).toFixed(3),
              1,
              true
            );
        },
        evolution_com: function (item, index)
        {
            let last = item;
            let before = this.logement_com[index - 1];
            return verify_null_percent(
              ((last - before) / before).toFixed(3),
              1,
              true
            );
        },
        evolution_menage: function (object, bool_sign = false)
        {
            let before = this.menages_additions[object][0];
            let last = this.menages_additions[object][
            this.menages_additions[object].length - 1
              ];
            return verify_null_percent(
              parseFloat(((last - before) / before).toFixed(3)),
              1,
              bool_sign
            );
        }
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        evoLineSeries: function ()
        {
            return [
                {
                    name: 'Logements',
                    data: this.logement_iris
                },
                {
                    name: 'Logements commune',
                    data: this.logement_com
                }
            ];
        },
        evoLineSeries_1: function ()
        {
            return [
                {
                    name: 'Ménages',
                    data: this.menages_iris
                }
            ];
        },
        Options: function ()
        {
            return {
                dataLabels: {
                    enabled: true
                },
                markers: {
                    size: 6
                },
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: this.annee_tab
                },
                yaxis: [
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.logement_iris),
                        max: Math.max(...this.logement_iris),
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB'
                            }
                        },
                        title: {
                            text: 'Logements',
                            style: {
                                color: '#008FFB'
                            }
                        }
                    },
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.logement_com),
                        max: Math.max(...this.logement_com),
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            style: {
                                colors: '#00E396'
                            }
                        },
                        title: {
                            text: 'Logements commune',
                            style: {
                                color: '#00E396'
                            }
                        }
                    }
                ]
            };
        },
        Options_1: function ()
        {
            return {
                dataLabels: {
                    enabled: true
                },
                markers: {
                    size: 6
                },
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: this.annee_tab
                },
                yaxis: [
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.menages_iris),
                        max: Math.max(...this.menages_iris),
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB'
                            }
                        },
                        title: {
                            text: 'Logements',
                            style: {
                                color: '#008FFB'
                            }
                        }
                    }
                ]
            };
        },
        searchInItem: function ()
        {
            let temp = this.item.selected_logement_iris.find(
              element => element.annee === this.annee
            );
            return temp;
        }
    },
    watch: {
        item: function (newValue, oldValue)
        {
            this.annee =
              newValue.selected_logement_iris[
              newValue.selected_logement_iris.length - 1
                ].annee;
            newValue.selected_logement_iris.forEach(element =>
            {
                this.annee_tab.push(element.annee);
                this.logement_iris.push(element.log);
                this.menages_iris.push(element.men);
                this.menages_additions['men_moins_2'].push(element.men_moins_2);
                this.menages_additions['men_2_a_4'].push(element.men_2_a_4);
                this.menages_additions['men_5_a_9'].push(element.men_5_a_9);
                this.menages_additions['men_plus_10'].push(element.men_plus_10);
            });
            for (const element of this.item.commune.selected_logement_com)
            {
                this.logement_com.push(element.log);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.truc {
    width: 100%;
    margin: 0.5rem 0px;
    overflow: hidden;
    border-bottom: 2px lightgray solid;

    p {
        margin: 0;
        float: left;
    }

    span {
        float: right;
    }
}

.titre_second {
    width: 100%;
    margin: 0.5rem 0px;
    overflow: hidden;
    border-bottom: 2px #58a4b0 solid;

    p {
        font-weight: bold;
        margin: 0;
        float: left;
        font-size: 1.3em;
    }

    span {
        float: right;
    }
}

#TEST {
    background-color: aqua;
    position: absolute;
    width: 400px;
    height: 200px;
    bottom: 0px;
}
</style>
