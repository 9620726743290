export let style_filter = (feature, layer, page, radius = null) =>
{
    switch (page)
    {
        case 'zone_tva':
            if (radius)
              return zone_elig_tva_radius_style(feature, layer, radius);
            else
              return zone_elig_tva_style(feature, layer);
        case 'operation_amen':
            return operation_amen_style(feature, layer);
        case 'points_interets':
            return points_interets(feature, layer);
        case 'zone_peb':
            return zone_peb_style(feature, radius);
        default:
            break;
    }
}

export let operation_amen_style = (feature, layer) =>
{
    let opacity = 0.2;
    switch (feature.properties.type)
    {
        case 'Activité':
            return {
                color: '#FFA400',
                fillColor: '#FFA400',
                fillOpacity: opacity
            };
        case 'En phase de réflexion':
            return {
                color: '#345995',
                fillColor: '#345995',
                fillOpacity: opacity
            };
        case 'Espace ouvert / Parc':
            return {
                color: '#E40066',
                fillColor: '#E40066',
                fillOpacity: opacity
            };
        case 'Logement':
            return {
                color: '#03CEA4',
                fillColor: '#03CEA4',
                fillOpacity: opacity
            };
        case 'Mixte Autre':
            return {
                color: '#FFA400',
                fillColor: '#FFA400',
                fillOpacity: opacity
            };
        case 'Mixte Logement / Activité':
            return {
                color: '#FB4D3D',
                fillColor: '#FB4D3D',
                fillOpacity: opacity
            };
        case 'Mixte Logement / Activité / Equipements':
            return {
                color: '#775DD0',
                fillColor: '#775DD0',
                fillOpacity: opacity
            };
        case 'nc':
            return {
                color: '#FF66C3',
                fillColor: '#FF66C3',
                fillOpacity: opacity
            };
        default:
            break;
    }
};

export let zone_elig_tva_style = (feature, layer) =>
{
    let blue_qpv = {
        'color': '#2DD2FC',
        'opacity': 0.6
    };
    let yellow_art6 = {
        'color': '#fc8800',
        'opacity': 0.6
    };
    let red_zus = {
        'color': '#ff0000',
        'opacity': 0.6
    };
    let grey_qpv = {
        'color': '#424141',
        'opacity': 0.6
    };


    switch (feature.properties.type)
    {
        case 'Conventionné Prioritaire, Arrêté du 29 avril 2015':
            return blue_qpv;
        case 'Conventionné Complémentaire, Arrêté du 15 janvier 2019':
            return blue_qpv;
        case 'ANRU - Art. 6':
            return yellow_art6;
        case 'ANRU - ZUS conv.':
            return red_zus;
        case 'ZUS conv.':
            return red_zus;
        case 'QPV 2024':
            return grey_qpv;
        default:
            break;
    }
};
export let zone_elig_tva_radius_style = (feature, layer, radius) =>
{
    let dashArray = radius === 300 ? '4' : '6';
    let blue_qpv = {
        'color': '#2DD2FC',
        'opacity': 0.6,
        'dashArray': dashArray
    };
    let blue_qpnv = {
        'opacity': 0.6,
        'dashArray': dashArray
    };
    let yellow_art6 = {
        'color': '#fc8800',
        'opacity': 0.6,
        'dashArray': dashArray
    };
    let red_zus = {
        'color': '#ff0000',
        'opacity': 0.6,
        'dashArray': dashArray
    };
    let grey_qpv = {
        'color': '#424141',
        'opacity': 0.6,
        'dashArray': dashArray
    };
    switch (feature.properties.type)
    {
        case 'Conventionné Prioritaire, Arrêté du 29 avril 2015':
            return blue_qpv;
        case 'Conventionné Complémentaire, Arrêté du 15 janvier 2019':
            return blue_qpv;
        case 'ANRU - Art. 6':
            return yellow_art6;
        case 'ANRU - ZUS conv.':
            return red_zus;
        case 'ZUS conv.':
            return red_zus;
        case 'QPV 2024':
            return grey_qpv;
        default:
            return blue_qpnv;
    }
};
export let points_interets = (feature, layer) =>
{
    if (feature.geometry.type === 'MultiPolygon')
    {
        let text = {
            'opacity': 0.6,
            'color': '#2DD021'
        };
        return text;
    }
};

export let zone_peb_style = (feature, radius) =>
{

    let blue = {
        'color': '#2DD2FC',
        'opacity': 0.6
    };
    let yellow = {
        'color': '#fc8800',
        'opacity': 0.6
    };
    let red = {
        'color': '#ff0000',
        'opacity': 0.6
    };
    let green = {
        'color': '#b0ffa6',
        'opacity': 0.6
    };

    if (radius)
    {
        let dashArray = radius === 300 ? '4' : '6';
        blue['dash_array'] = dashArray;
        yellow['dash_array'] = dashArray;
        red['dash_array'] = dashArray;
        green['dash_array'] = dashArray;
    }

    switch (feature.properties.zone)
    {
        case 'A':
            return red;
        case 'B':
            return yellow;
        case 'C':
            return blue;
        case 'D':
            return green;
        default:
            return;
    }
};
