<template>
  <v-card width="50%" id="geocoder_card" style="margin: auto;">
    <v-autocomplete
      class="geocoder_input"
      v-model="model"
      :loading="isLoading"
      :items="items"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      item-text="label"
      placeholder=" Adresse"
      prepend-icon="mdi-map-search-outline"
      return-object
      dense
      no-filter
      clearable
    ></v-autocomplete>
  </v-card>
</template>

<script>
import { GeoApiFrProvider } from 'leaflet-geosearch';
export default {
  data() {
    return {
      model: null,
      search: null,
      isLoading: false,
      provider: new GeoApiFrProvider({
        autoComplete: true, // optional: true|false  - default true
        autoCompleteDelay: 250 // optional: number      - default 250
      }),
      entries: []
    };
  },
  watch: {
    search(val) {
      console.log(val);
      if (val?.length >= 2 && val != null && val != '') {
        this.provider.search({ query: val }).then(result => {
          this.entries = result;
        });
      }
    },
    model(val) {
      if (val != null) {
        this.$emit('result_searching', val);
      }
    }
  },
  computed: {
    // items() {
    //   return this.entries.map(entry => {
    //     return entry.label;
    //   });
    // }
    items() {
      return this.entries.map(entry => {
        const Description =
          entry.label.length > 60
            ? entry.label.slice(0, 60) + '...'
            : entry.label;

        return Object.assign({}, entry, { Description });
      });
    }
    // fields() {
    //   if (!this.model) return [];

    //   return Object.keys(this.model).map(key => {
    //     return {
    //       key,
    //       value: this.model[key] || 'n/a'
    //     };
    //   });
    // }
  }
};
</script>

<style lang="scss">
#geocoder_card {
  z-index: 20000000;
  padding-bottom: 0;
  margin-left: 500px;
  .geocoder_input {
    .v-input__slot {
      margin-bottom: 0;
      &:after {
        border-color: red;
      }
      &:before {
        border-width: 0;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
