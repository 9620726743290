<template>
    <b-container>
        <b-row>
            <b-col>
                <h5 style="text-align:left;font-family:Gotham;">
                    Type de quartier <small>(en cours de mise à jour)</small>
                </h5>
                <v-divider></v-divider>
                <b-form-checkbox-group
                  @click.native="addMoreFilter($event)"
                  style="text-align: left;"
                  id="btnradios3"
                  stacked
                  button-variant="outline-secondary"
                  v-model="getFilter"
                  :options="keyOptions"
                  name="radioBtnStacked"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'FiltreZoneTva',
    data()
    {
        return {
            keyOptions: [
                {text: 'Zones Urbaines Sensibles', value: 'ANRU - ZUS conv.'},
                {
                    text: 'Quartiers Prioritaires de la Ville non conventionné',
                    value: 'QPV non conventionné'
                },
                {
                    text: 'Quartiers Prioritaires de la Ville conventionné',
                    value: 'Conventionné Prioritaire, Arrêté du 29 avril 2015'
                },
              {
                text: 'Quartiers Prioritaires de la Ville 2024',
                value: 'QPV 2024'
              },
                {text: "Afficher les zones étendues d'éligibilité", value: 'etendu'}
            ],
            filter_selected: [
                'ANRU - ZUS conv.',
                'QPV non conventionné',
                'Conventionné Prioritaire, Arrêté du 29 avril 2015',
                'Conventionné Complémentaire, Arrêté du 15 janvier 2019',
                'ZUS conv.',
                'QPV 2024'
            ]
        };
    },
    mounted()
    {
        if (this.saved_filter.length === 0)
        {
            this.$store.commit('mutateFilter', this.filter_selected);
        }
    },
    watch: {
        filter_selected(newValue, oldValue)
        {
            this.$store.commit('mutateFilter', newValue);
        }
    },
    computed: {
        ...mapGetters({saved_filter: 'getFilter'}),
        getFilter: {
            get()
            {
                return this.saved_filter;
            },
            set(newSaved_filter)
            {
                this.filter_selected = newSaved_filter;
            }
        }
    },
    methods: {
        addMoreFilter(event)
        {
            let filter_item_1 =
              'Conventionné Complémentaire, Arrêté du 15 janvier 2019';
            let filter_item_2 = 'ZUS conv.';
            switch (event.target._value)
            {
                case 'Conventionné Prioritaire, Arrêté du 29 avril 2015':
                    if (this.filter_selected.includes(event.target._value))
                    {
                        this.filter_selected.splice(
                          this.filter_selected.indexOf(filter_item_1),
                          1
                        );
                    }
                    else
                    {
                        this.filter_selected.push(filter_item_1);
                    }
                    break;
                case 'ANRU - ZUS conv.':
                    if (this.filter_selected.includes(event.target._value))
                    {
                        this.filter_selected.splice(
                          this.filter_selected.indexOf(filter_item_2),
                          1
                        );
                    }
                    else
                    {
                        this.filter_selected.push(filter_item_2);
                    }
                    break;
                default:
                    break;
            }
        }
    }
};
</script>

<style></style>
