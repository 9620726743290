<template>
    <div>
        <v-list-item class="pt-2">
            <h5 class="flex justify-self-center pt-2">Légende</h5>
        </v-list-item>
        <v-divider class="mt-0"></v-divider>
        <v-list-item>
            <div class="square" style="background-color: #FFA400;"/>
            <span class="body-2">
        : Activité
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #345995;"/>
            <span class="body-2">
        : En phase de réflexion
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #E40066;"/>
            <span class="body-2">
        : Espace ouvert / Parc
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #03CEA4;"/>
            <span class="body-2">
        : Logement
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #FB4D3D;"/>
            <span class="body-2">
        : Mixte Logement / Activité
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #775DD0;"/>
            <span class="body-2">
        : Mixte Logement / Activité / Equipements
      </span>
        </v-list-item>
        <v-list-item>
            <div class="square" style="background-color: #FFA400;"/>
            <span class="body-2">
        : Mixte Autre
      </span>
        </v-list-item>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
