import L from 'leaflet';
import store from '../../store';

export let pointToLayerFunction = (feature, latlng, page) =>
{
    switch (page)
    {
        case 'transports':
            return pointToLayerFunction_Transport(feature, latlng);
        case 'etablissements_scolaires':
            return pointToLayerFunction_Scolaire(feature, latlng);
        case 'permis_construire':
            return pointToLayerFunction_Permis_construire(feature, latlng);
        case 'programmes_neufs':
            return pointToLayerFunction_Programmes_neufs(feature, latlng);
        case 'commerce':
            return pointToLayerFunction_Commerce(feature, latlng);
        case 'logements_sociaux':
            return pointToLayerFunction_Logements_sociaux(feature, latlng);
        case 'points_interets':
            return pointToLayerFunction_Points_interets(feature, latlng);
        case 'points_vigilance':
            return pointToLayerFunction_Points_vigilance(feature, latlng);
        case 'marche_ancien_geoloc':
            return pointToLayerFunction_Marche_ancien_geoloc(feature, latlng);
        case 'marche_neuf_geoloc':
            return pointToLayerFunction_Marche_ancien_geoloc(feature, latlng);
        default:
            break;
    }
};
export let onEachFeatureFunction = (feature, layer, page) =>
{
    switch (page)
    {
        case 'transports':
            return onEachFeatureFunction_Transport(feature, layer);
        case 'commerce':
            return onEachFeatureFunction_Commerce(feature, layer);
        case 'etablissements_scolaires':
            return onEachFeatureFunction_Scolaire(feature, layer);
        case 'zone_tva':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'permis_construire':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'programmes_neufs':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'logements_sociaux':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'operation_amen':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'points_vigilance':
            return onEachFeaturePoints_vigilance(feature, layer, page);
        case 'points_interets':
            return onEachFeatureFunction_Points_interet(feature, layer, page);
        case 'marche_ancien_geoloc':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'marche_neuf_geoloc':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        case 'zone_peb':
            return onEachFeatureFunction_Show_card(feature, layer, page);
        default:
            break;
    }
};

let getDistance = (origin, destination) =>
{
    let p2 = L.latLng(destination[0], destination[1]);
    return origin.distanceTo(p2);
};
let get_marker_distance = (marker_location, latlng) =>
{
    if (marker_location)
    {
        let dist = Math.round(
            getDistance(latlng, [marker_location.y, marker_location.x])
        ); // en metres

        let vitesse = 3500 / 60; // exprime en mettre par minute
        let res = dist / vitesse;

        let min_res = parseFloat(res).toFixed(0);
        return min_res < 1 ? 1 : min_res; // en minute
    }
    else
    {
        return 0;
    }
};

let pointToLayerFunction_Transport = (feature, latlng) =>
{
    let icon;
    switch (feature.properties.type)
    {
        case 'BUS':
            icon = 'bus';
            break;
        case 'METRO':
            icon = 'metro';
            if (feature.properties.arret_futur) icon = 'metro-futur';
            break;
        case 'TRAIN':
            icon = 'train';
            if (feature.properties.arret_futur) icon = 'train-futur';
            break;
        case 'TRAMWAY':
            icon = 'tramway';
            if (feature.properties.arret_futur) icon = 'tramway-futur';
            break;
        default:
            return;
    }
    let min_dist = '';
    if (store.getters.get_marker_location)
    {
        min_dist = `<strong style="white-space: nowrap;align-self:center;margin:0;">${get_marker_distance(
            store.getters.get_marker_location,
            latlng
        )} min</strong>`;
    }

    let require_icon = require(`@/assets/${icon}.png`);

    var html_marker =
        '<div class="icon_container" style="display: flex;width: fit-content;">' +
        `<img class="icon_img" src="${require_icon}")>` +
        min_dist +
        '</div>';

    if (feature.properties.arret_futur)
        html_marker =
            '<a href="' +
            feature.properties.lien_projet +
            '" target="_blank"><div class="icon_container" style="display: flex;width: fit-content;">' +
            `<img class="icon_img" src="${require_icon}")>` +
            min_dist +
            '</div></a>';

    let myIcon = L.divIcon({
        className: 'class_icon',
        iconSize: [32, 32],
        html: html_marker
    });

    return L.marker(latlng, {
        icon: myIcon
    });
};
let pointToLayerFunction_Points_interets = (feature, latlng) =>
{
    let icon;
    if (feature.geometry.type === 'Point')
    {
        switch (feature.properties.type)
        {
            case 'Mairie':
                icon = 'mairie';
                break;
            case 'Hôpital':
                icon = 'hopital';
                break;
            case 'Pompiers':
                icon = 'pompier';
                break;
            case 'Gendarmerie/Police':
                icon = 'police';
                break;
            case 'Préfecture':
            case 'Sous-Préfecture':
                icon = 'administration';
                break;
            default:
                break;
        }
        let min_dist = '';
        if (store.getters.get_marker_location)
        {
            min_dist = `<strong style="white-space: nowrap;align-self:center;margin:0;">${get_marker_distance(
                store.getters.get_marker_location,
                latlng
            )} min</strong>`;
        }

        let require_icon = require(`@/assets/${icon}.png`);

        let html_marker =
            '<div class="icon_container" style="display: flex;width: fit-content;">' +
            `<img class="icon_img" src="${require_icon}")>` +
            min_dist +
            '</div>';

        let myIcon = L.divIcon({
            className: 'class_icon',
            iconSize: [32, 32],
            html: html_marker
        });

        return L.marker(latlng, {
            icon: myIcon
        });
    }
};
let pointToLayerFunction_Points_vigilance = (feature, latlng) =>
{
    let icon;
    switch (feature.properties.type_pv)
    {
        case 'Décheterie':
            icon = 'dechetterie';
            break;
        case 'SEVESO':
            icon = 'site_seveso';
            break;
        case "Station d'épuration":
            icon = 'station_epuration';
            break;
        case 'Station essence':
            icon = 'station_service';
            break;
        case 'Transformateur électrique':
            icon = 'transformateur';
            break;
        default:
            break;
    }
    let min_dist = '';
    if (store.getters.get_marker_location)
    {
        min_dist = `<strong style="white-space: nowrap;align-self:center;margin:0;">${get_marker_distance(
            store.getters.get_marker_location,
            latlng
        )} min</strong>`;
    }

    let require_icon = require(`@/assets/${icon}.png`);

    let html_marker =
        '<div class="icon_container" style="display: flex;width: fit-content;">' +
        `<img class="icon_img" src="${require_icon}")>` +
        min_dist +
        '</div>';

    let myIcon = L.divIcon({
        className: 'class_icon',
        iconSize: [32, 32],
        html: html_marker
    });

    return L.marker(latlng, {
        icon: myIcon
    });
};
let pointToLayerFunction_Scolaire = (feature, latlng) =>
{
    let icon;
    if (
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('maternelle')
    )
    {
        icon = 'maternelle';
    }
    else if (
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('primaire') ||
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('elementaire') ||
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('élémentaire')
    )
    {
        icon = 'primaire';
    }
    else if (
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('collège') ||
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('college') ||
        feature.properties.appellation_officielle.toLowerCase().includes('lycée') ||
        feature.properties.appellation_officielle.toLowerCase().includes('lycee')
    )
    {
        icon = 'school';
    }
    else if (
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('universit') ||
        feature.properties.appellation_officielle
            .toLowerCase()
            .includes('secondaire')
    )
    {
        icon = 'secondaire';
    }
    else
    {
        icon = 'question';
    }
    let min_dist = '';
    if (store.getters.get_marker_location)
    {
        min_dist = `<strong style="white-space: nowrap;align-self:center;margin:0;">${get_marker_distance(
            store.getters.get_marker_location,
            latlng
        )} min</strong>`;
    }

    let require_icon = require(`@/assets/${icon}.png`);

    let html_marker =
        '<div class="icon_container" style="display: flex;width: fit-content;">' +
        `<img class="icon_img" src="${require_icon}")>` +
        min_dist +
        '</div>';

    let myIcon = L.divIcon({
        className: 'class_icon',
        iconSize: [32, 32],
        html: html_marker
    });

    return L.marker(latlng, {
        icon: myIcon
    });
};
let pointToLayerFunction_Programmes_neufs = (feature, latlng) =>
{
    if (feature.properties.statut === 'En cours')
    {
        return L.circle(latlng, {radius: 10});
    }
    else
    {
        return L.circle(latlng, {
            radius: 10,
            fillColor: 'red',
            color: 'red'
        });
    }
};
let pointToLayerFunction_Logements_sociaux = (feature, latlng) =>
{
    return L.circle(latlng, {
        radius: 3
    });
};

let pointToLayerFunction_Marche_ancien_geoloc = (feature, latlng) =>
{
    return L.circle(latlng, {
        radius: 3,
        fillColor: 'green',
        color: 'green'
    });
};
let pointToLayerFunction_Commerce = (feature, latlng) =>
{
    let icon;
    switch (feature.properties.type)
    {
        case 'bank':
            icon = 'banque';
            break;
        case 'restaurant':
            icon = 'restaurant';
            break;
        case 'fast_food':
            icon = 'restaurant';
            break;
        case 'post_office':
            icon = 'poste';
            break;
        case 'pharmacy':
            icon = 'pharmacie';
            break;
        case 'supermarket':
            icon = 'alimentation';
            break;
        case 'mall':
            icon = 'alimentation';
            break;
        case 'general':
            icon = 'alimentation';
            break;
        case 'bar_cafe_tabac ':
            icon = 'bar';
            break;

        default:
            break;
    }

    if (icon)
    {
        let min_dist = '';
        if (store.getters.get_marker_location)
        {
            min_dist = `<strong style="white-space: nowrap;align-self:center;margin:0;">${get_marker_distance(
                store.getters.get_marker_location,
                latlng
            )} min</strong>`;
        }

        let require_icon = require(`@/assets/${icon}.png`);

        let html_marker =
            '<div class="icon_container" style="display: flex;width: fit-content;">' +
            `<img class="icon_img" src="${require_icon}")>` +
            min_dist +
            '</div>';

        let myIcon = L.divIcon({
            className: 'class_icon',
            iconSize: [32, 32],
            html: html_marker
        });

        return L.marker(latlng, {
            icon: myIcon
        });
    }
};
let pointToLayerFunction_Permis_construire = (feature, latlng) =>
{
    return L.marker(latlng, {
        icon: L.icon({
            iconUrl: require(`@/assets/crane.png`),
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            popupAnchor: [0, -5]
        })
    });
};
let onEachFeatureFunction_Transport = (feature, layer) =>
{
    layer.bindTooltip(
        `${feature.properties.nom_arret}<br/>Ligne de ${feature.properties.type} : ${feature.properties.numero_ligne}`
    ),
        {permanent: false, sticky: true};
};
let onEachFeatureFunction_Commerce = (feature, layer) =>
{
    let stringType;
    switch (feature.properties.type)
    {
        case 'bank':
            stringType = 'Banque';
            break;
        case 'pharmacy':
            stringType = 'Pharmacie';
            break;
        case 'fast_food':
            stringType = 'Fast-Food';
            break;
        case 'restaurant':
            stringType = 'Restaurant';
            break;
        case 'post_office':
            stringType = 'Poste';
            break;
        case 'supermarket':
            stringType = 'Alimentation générale';
            break;
        case 'alimentation':
            stringType = 'Alimentation générale';
            break;

        default:
            break;
    }
    layer.bindTooltip(stringType), {permanent: false, sticky: true};
};
let onEachFeatureFunction_Points_interet = (feature, layer) =>
{
    layer.bindTooltip(
        `<p>${feature.properties.type}</p><p>${feature.properties.nom_pi}</p>`
    ),
        {permanent: false, sticky: true};
};
let onEachFeaturePoints_vigilance = (feature, layer) =>
{
    layer.bindTooltip(feature.properties.nom_pv),
        {permanent: false, sticky: true};
};
let onEachFeatureFunction_Scolaire = (feature, layer) =>
{
    layer.bindTooltip(feature.properties.appellation_officielle),
        {permanent: false, sticky: true};

};

let onEachFeatureFunction_Show_card = (feature, layer, page) =>
{
    layer.on({
        click: ({target}) =>
        {
            target.feature.properties.page = page;
            if (typeof target.feature.properties.id == 'string')
            {
                target.feature.properties.id = target.feature.properties.id.replace(
                    /_/g,
                    ''
                );
            }
            store.dispatch('actionSelected_item', target.feature.properties);
        }
    });
};
