<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>Préconisations INOVEFA pour {{ item.nom_com }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="qualite" md="8">
                <p class="h5 font-weight-bold">Commentaire</p>
                <p class="contenu body-1">
                    {{ item.selected_preco_com[0].com_quali }}
                </p>
            </b-col>
            <b-col class="potentiel" md="4">
                <p class="h5 font-weight-bold">Potentiel marché</p>
                <p class="contenu body-1">
                    {{ item.selected_preco_com[0].potentiel_marche }}
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="6">
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>
                                Prix
                            </b-th>
                            <b-th>
                                BAS
                            </b-th>
                            <b-th>
                                HAUT
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>
                                {{ item.nom_com }}
                            </b-td>
                            <b-td>
                                {{
                                    item.selected_preco_com[0].prix_bas === -1
                                      ? 'Non applicable'
                                      : verify_null_currency(item.selected_preco_com[0].prix_bas)
                                }}
                            </b-td>
                            <b-td>
                                {{
                                    item.selected_preco_com[0].prix_haut === -1
                                      ? 'Non applicable'
                                      : verify_null_currency(item.selected_preco_com[0].prix_haut)
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_currency} from './functions_utilities';

export default {
    name: 'PrecoCom',
    data()
    {
        return {};
    },
    methods: {
        verify_null_currency: verify_null_currency
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
    }

    .contenu {
        text-align: left;
    }
}
</style>
