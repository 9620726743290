<template>
    <b-container v-if="item && selected_parc_social">
        <b-row id="titre">
            <b-col class="pl-0 pr-0"
            ><h4>
                Fiche d'identité du parc social de la commune de {{ item.nom_com }}
            </h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="6">
                <div>
                    <p>
                        Nombre de logements dans le parc social
                    </p>
                    <span>{{
                            verify_null_number(selected_parc_social.nb_logement_parc)
                        }}</span>
                </div>
            </b-col>

            <b-col col md="6">
                <div>
                    <p>
                        Part de logements sociaux sur le nombre de résidences principales
                        (2017)
                    </p>
                    <span>
            {{
                            selected_parc_social.taux_logements_sociaux === -1
                              ? 'Donnée inconnue'
                              : verify_null_percent(
                                selected_parc_social.taux_logements_sociaux
                              )
                        }}
          </span>
                </div>
            </b-col>
            <div class="w-100"></div>
            <b-col>
                <div>
                    <p>
                        Évolution du nombre de logements dans le parc depuis 2017
                    </p>
                    <span
                      :style="
              color_evolution(
                verify_null_percent(selected_parc_social.evolution_2014_2019, 1)
              )
            "
                    >
            {{
                            verify_null_percent(
                              selected_parc_social.evolution_2014_2019,
                              1,
                              true
                            )
                        }}
          </span>
                </div>
            </b-col>
            <b-col col md="12">
                <b-table-simple bordered>
                    <b-thead>
                        <b-th>Loyer moyen 2022, parc complet</b-th>
                        <b-th>PLAI</b-th>
                        <b-th>PLUS avant 1977</b-th>
                        <b-th>PLUS après 1977</b-th>
                        <b-th>PLS</b-th>
                        <b-th>PLI</b-th>
                        <b-th>Autres financements</b-th>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td> {{ selected_parc_social.loyer_moyen }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.plai }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.plus_avant_1977 }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.plus_apres_1977 }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.pls }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.pli }} €/m²</b-td>
                            <b-td> {{ selected_parc_social.autres_financements }} €/m²</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>

        <b-row>
            <b-col col md="12" class="pl-0 pr-0" id="titre"
            ><h4>
                Composition du parc
            </h4>
            </b-col>
            <b-col col md="6">
                <h4>Nombre de pièces</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_3"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col col md="6">
                <h4>Appartements/maisons</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_2"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col>
                <h4>Occupés/vacants</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_1"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col align-self="center">
                <div class="mb-4">
                    <p class="mb-2">
                        Taux de vacance global
                    </p>
                    <span>
            {{
                            selected_parc_social.taux_vacance === -1
                              ? 'Donnée inconnue'
                              : selected_parc_social.taux_vacance + ' %'
                        }}
          </span>
                </div>
                <div>
                    <p class="mb-2">
                        ... à plus de 3 mois
                    </p>
                    <span>
            {{
                            selected_parc_social.taux_vacance_sup_3_mois === -1
                              ? 'Donnée inconnue'
                              : selected_parc_social.taux_vacance_sup_3_mois + ' %'
                        }}
          </span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="12" class="pl-0 pr-0" id="titre"
            ><h4>
                Méthode d'acquisition / financement / âge du parc
            </h4>
            </b-col>
            <b-col col md="6">
                <h4>Méthode d'acquisition</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_4"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col col md="6">
                <h4>Financement</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_5"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col col md="6">
                <h4>Age du parc</h4>
                <apexchart
                  type="bar"
                  height="200"
                  :series="evoBarSeries_6"
                  :options="Options_1"
                ></apexchart>
            </b-col>
            <b-col col md="6" align-self="center">
                <div>
                    <p class="mb-2">
                        Age moyen du parc
                    </p>
                    <span>
            {{
                            selected_parc_social.age_moyen_parc === -1
                              ? 'Donnée inconnue'
                              : selected_parc_social.age_moyen_parc + ' années'
                        }}
          </span>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'ParcSocial',
    data()
    {
        return {
            selected_parc_social: null,
            composition_parc: [],
            loyer_moyen_mensuel: [],
            loyer_moyen_mensuel_mc: [],
            shab_moyen: [],
            Options_1: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%'
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['']
                },
                tooltip: {
                    y: {
                        formatter: function (val)
                        {
                            return val;
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                }
            }
        };
    },
    methods: {
        verify_null_currency: tools.verify_null_currency,
        verify_null_number: tools.verify_null_number,
        verify_null_percent: tools.verify_null_percent,
        color_evolution: tools.color_evolution
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        evoBarSeries_1: function ()
        {
            return [
                {
                    name: 'Logements loués',
                    data: [this.selected_parc_social.part_loue]
                },
                {
                    name: 'Logements vacants',
                    data: [this.selected_parc_social.part_vacant]
                }
            ];
        },
        evoBarSeries_2: function ()
        {
            return [
                {
                    name: 'Appartements',
                    data: [this.selected_parc_social.part_appartements]
                },
                {
                    name: 'Maisons',
                    data: [this.selected_parc_social.part_maisons]
                }
            ];
        },
        evoBarSeries_3: function ()
        {
            return [
                {
                    name: '1 pièce',
                    data: [this.selected_parc_social.part_t1]
                },
                {
                    name: '2 pièces',
                    data: [this.selected_parc_social.part_t2]
                },
                {
                    name: '3 pièces',
                    data: [this.selected_parc_social.part_t3]
                },
                {
                    name: '4 pièces',
                    data: [this.selected_parc_social.part_t4]
                },
                {
                    name: '5 pièces',
                    data: [this.selected_parc_social.part_t5]
                }
            ];
        },
        evoBarSeries_4: function ()
        {
            return [
                {
                    name: "Construit par l'organisme (2018)",
                    data: [this.selected_parc_social.part_construction_organisme]
                },
                {
                    name: 'Acquis avec travaux',
                    data: [this.selected_parc_social.part_acquisition_avec_travaux]
                },
                {
                    name: 'Acquis sans travaux',
                    data: [this.selected_parc_social.part_acquisition_sans_travaux]
                },
                {
                    name: 'Acquis en VEFA',
                    data: [this.selected_parc_social.part_acquisition_vefa]
                }
            ];
        },
        evoBarSeries_5: function ()
        {
            return [
                {
                    name: 'PLAI',
                    data: [this.selected_parc_social.part_plai]
                },
                {
                    name: 'PLUS avant 1977',
                    data: [this.selected_parc_social.part_plus_avant_1977]
                },
                {
                    name: 'PLUS après 1977',
                    data: [this.selected_parc_social.part_plus_apres_1977]
                },
                {
                    name: 'PLS',
                    data: [this.selected_parc_social.part_pls]
                },
                {
                    name: 'PLI',
                    data: [this.selected_parc_social.part_pli]
                },
                {
                    name: 'Autres financements',
                    data: [this.selected_parc_social.part_autres_financements]
                }
            ];
        },
        evoBarSeries_6: function ()
        {
            return [
                {
                    name: '5 ans ou moins',
                    data: [this.selected_parc_social.part_5_ans_moins]
                },
                {
                    name: 'Entre 5 et 10 ans',
                    data: [this.selected_parc_social.part_5_10_ans]
                },
                {
                    name: 'Entre 10 et 20 ans',
                    data: [this.selected_parc_social.part_10_20_ans]
                },
                {
                    name: 'Entre 20 et 40 ans',
                    data: [this.selected_parc_social.part_20_40_ans]
                },
                {
                    name: 'Entre 40 et 60 ans',
                    data: [this.selected_parc_social.part_40_60_ans]
                },
                {
                    name: 'Plus de 60 ans',
                    data: [this.selected_parc_social.part_60_ans_plus]
                }
            ];
        }
    },
    watch: {
        item: function (newValue, olValue)
        {
            this.selected_parc_social = newValue.selected_parc_social[0];
        }
    }
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.paragraphe {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}
</style>
