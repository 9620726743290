<template>
    <div>
        <h5 class="flex justify-self-center pt-2 h4">Légende</h5>
        <v-divider class="mt-0"></v-divider>
        <v-list-item dense>
            <div class="square" style="background-color: #2DD2FC;"/>
            <span class="body-2">
        : Quartiers Prioritaires de la ville conventionné</span
            >
        </v-list-item>
        <v-list-item dense>
            <div class="square" style="background-color: #3388FF;"/>
            <span class="body-2">
        : Quartiers Prioritaires de la ville non conventionné
      </span>
        </v-list-item>
        <v-list-item dense>
                <div class="square" style="background-color: #ff0000;"/>
                <span class="body-2">
            : Zones Urbaines Sensibles
          </span>
        </v-list-item>
      <v-list-item dense>
        <div class="square" style="background-color: #424141;"/>
        <span class="body-2">
        : Quartiers Prioritaires de la ville 2024
      </span>
      </v-list-item>
		<v-list-item dense>
			<span class="body-2">
				Cette rubrique est en cours de mise à jour au regard des récentes évolutions des QPV.
		  </span>
		</v-list-item>
    </div>
</template>

<script>
export default {};
</script>

<style>
.square {
    width: 15px;
    height: 15px;
    border-radius: 25px;
    margin-right: 1em;
}
</style>
