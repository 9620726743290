import { render, staticRenderFns } from "./Population.vue?vue&type=template&id=3eb21cee&scoped=true&"
import script from "./Population.vue?vue&type=script&lang=js&"
export * from "./Population.vue?vue&type=script&lang=js&"
import style0 from "./Population.vue?vue&type=style&index=0&id=3eb21cee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb21cee",
  null
  
)

export default component.exports