<template>
    <div>
        <l-geo-json
          :geojson="geojsonData.geojson"
          :options="geojsonOptions"
          ref="geolayer"
        ></l-geo-json>
        <slot
          :currentItem="currentItem"
          :unit="value.metric"
          :min="min"
          :max="max"
        ></slot>
    </div>
</template>

<script>
import L from 'leaflet';
import {LGeoJson} from 'vue2-leaflet';
import {
    getMin,
    getMax,
    getColor,
    validNumber
} from './util.js';
import {mapGetters} from 'vuex';
import {verify_null_number} from '../functions_utilities.js';

function mouseover({target})
{
    target.setStyle({
        weight: parseInt(this.currentStrokeWidth),
        color: `#${this.currentStrokeColor}`,
        dashArray: ''
    });
    if (!L.Browser.ie && !L.Browser.opera)
    {
        target.bringToFront();
    }
    let geojsonItem = target.feature.properties;
    let item = this.geojsonData.data.find(
      x => x[this.idKey] === geojsonItem[this.geojsonIdKey]
    );

    if (!item)
    {
        this.currentItem = {name: '', value: 0};
        return;
    }

    let tempItem = {
        name: item[this.titleKey],
        value: item[this.value.key]
    };
    if (this.extraValues)
    {
        let tempValues = [];
        for (let x of this.extraValues)
        {
            tempValues.push({
                value: item[x.key],
                metric: x.metric
            });
        }
        tempItem = {...tempItem, extraValues: tempValues};
    }
    this.currentItem = tempItem;
}

function mouseout({target})
{
    target.setStyle({
        weight: this.strokeWidth,
        color: `#${this.strokeColor}`,
        dashArray: ''
    });
    this.currentItem = {name: '', value: 0};
}

function mouseclick({target})
{
    let geojsonItem = target.feature.properties;
    let item = this.geojsonData.data.find(
      x => x[this.idKey] === geojsonItem[this.geojsonIdKey]
    );

    if (item)
    {
        let config = this.$store.getters.getConfig_selected;
        if (config.second?.active)
        {
            item.page = config.second.page;
            this.$store.dispatch('actionSelected_item', item);
        }
        else
        {
            item.page = config.page;
            this.$store.dispatch('actionSelected_item', item);
        }
    }
}

export default {
    props: {
        geojson: Object,
        data: Array,
        center: Array,
        colorScale: Array,
        titleKey: String,
        idKey: String,
        value: Object,
        extraValues: Array,
        geojsonIdKey: String,
        mapStyle: Object,
        zoom: Number,
        mapOptions: Object,
        strokeColor: {type: String, default: 'fff'},
        currentStrokeColor: {type: String, default: '666'},
        strokeWidth: {type: Number, default: 2},
        currentStrokeWidth: {type: Number, default: 5}
    },
    mounted()
    {
        if (this.$parent._isMounted)
        {
            this.deferredMountedTo(this.$parent.mapObject);
        }
    },
    data()
    {
        return {
            currentItem: {name: '', value: 0},
            geojsonOptions: {
                style: feature =>
                {
                    let itemGeoJSONID = feature.properties[this.geojsonIdKey];
                    const {data} = this.geojsonData;
                    let item = data.find(x => x[this.idKey] === itemGeoJSONID);
                    if (!item)
                    {
                        return {
                            color: 'lightgray',
                            weight: this.strokeWidth
                        };
                    }
                    let valueParam = Number(item[this.value.key]);
                    if (!validNumber(valueParam))
                    {
                        return {
                            color: 'lightgray',
                            weight: this.strokeWidth
                        };
                    }
                    const {min, max} = this;

                    return {
                        weight: this.strokeWidth,
                        opacity: 1,
                        color: `#${this.strokeColor}`,
                        fillOpacity: 0.7,
                        fillColor: getColor(valueParam, this.colorScale, min, max)
                    };
                },
                onEachFeature: (feature, layer) =>
                {
                    layer.on({
                        mouseover: mouseover.bind(this),
                        mouseout: mouseout.bind(this),
                        click: mouseclick.bind(this)
                    });
                    if (
                      this.config_selected.label ||
                      this.config_selected.second?.label
                    )
                    {
                        if (this.config_selected.page === 'cap_achat')
                        {
                            layer.bindTooltip(
                              `${
                                feature.properties.key
                                  ? `<strong>${feature.properties.key}</strong> €/m²`
                                  : '<strong>Inconnu</strong>'
                              }`,
                              {
                                  permanent: true
                              }
                            );
                        }
                        if (this.config_selected.page === 'pinel')
                        {
                            layer.bindTooltip(
                              `${
                                feature.properties.zone
                                  ? `<strong>${feature.properties.zone}</strong>`
                                  : '<strong>Inconnu</strong>'
                              }`,
                              {
                                  permanent: true
                              }
                            );
                        }
                        if (this.config_selected.page === 'couverture_fibre')
                        {
                            if (feature.properties.nom_com && feature.properties.key)
                            {
                                layer.bindTooltip(
                                  `${`<strong>${feature.properties.nom_com} </br>
                  ~${feature.properties.key}%</strong>`
                                  }`,
                                );
                            }
                            else if (!feature.properties.nom_com && feature.properties.key)
                            {
                                layer.bindTooltip(
                                  `${`<strong>~${feature.properties.key}%</strong>`
                                  }`,
                                );
                            }
                            else if (feature.properties.nom_com && !feature.properties.key)
                            {
                                layer.bindTooltip(
                                  `${`<strong>${feature.properties.nom_com}</strong>`
                                  }`,
                                );
                            }
                            else
                            {
                                layer.bindTooltip(
                                  `${`<strong>Inconnu</strong>`
                                  }`,
                                );
                            }
                        }
                        if (this.config_selected.page === 'preco_com')
                        {
                            let prix_haut =
                              feature.properties.prix_haut == null
                                ? 'Inconnu'
                                : feature.properties.prix_haut;
                            layer.bindTooltip(
                              `${`<strong>${verify_null_number(
                                feature.properties.prix_bas
                              )} - ${verify_null_number(prix_haut)} </strong>€/m²`}`,
                              {
                                  permanent: true
                              }
                            );
                        }

                        if (
                          this.config_selected.second?.active &&
                          this.config_selected.second?.page === 'preco_iris'
                        )
                        {
                            let prix_bas = feature.properties.prix_bas;
                            let prix_haut = feature.properties.prix_haut;
                            let tva = false;

                            if (feature.properties.prix_bas_tva !== 0)
                            {
                                prix_bas = feature.properties.prix_bas_tva;
                                tva = true;
                            }
                            else if (prix_bas === 0 || prix_bas === 1)
                            {
                                prix_bas = 'Inconnu';
                            }
                            if (feature.properties.prix_haut_tva !== 0)
                            {
                                prix_haut = feature.properties.prix_haut_tva;
                                tva = true;
                            }
                            else if (prix_haut === 0 || prix_haut === 1)
                            {
                                prix_haut = 'Inconnu';
                            }

                            if (prix_haut === 'Inconnu' && prix_bas === 'Inconnu')
                            {
                                layer.bindTooltip(`<strong>Inconnu</strong>`, {
                                    permanent: true
                                });
                            }
                            else
                            {
                                layer.bindTooltip(
                                  `${
                                    tva ? '<strong>TVA RÉDUITE : <br /></strong>' : ''
                                  }<strong>${verify_null_number(
                                    prix_bas
                                  )} - ${verify_null_number(prix_haut)}</strong> €/m²`,
                                  {
                                      permanent: true
                                  }
                                );
                            }
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            config_selected: 'getConfig_selected'
        }),
        min()
        {
            return getMin(this.geojsonData.data, this.value.key);
        },
        max()
        {
            return getMax(this.geojsonData.data, this.value.key);
        },
        geojsonData()
        {
            return {geojson: {...this.geojson}, data: this.data};
        }
    },
    components: {
        LGeoJson
    },
    methods: {
        deferredMountedTo(parent)
        {
            this.parent = parent;
            for (var i = 0; i < this.$children.length; i++)
            {
                if (typeof this.$children[i].deferredMountedTo === 'function')
                {
                    this.$children[i].deferredMountedTo(parent);
                }
            }
        }
    }
};
</script>
