<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>{{ item.lieu }}</h4>
            </b-col>
            <b-col col md="12">
                <b-table-simple class="table text-left" responsive bordered fixed>
                    <b-tr>
                        <b-th class="head">Nom d'opération</b-th>
                        <b-td>{{ item.nom_ope }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">Vocation</b-th>
                        <b-td>{{ item.type }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">Nature</b-th>
                        <b-td>{{ item.nature }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">État d'aménagement</b-th>
                        <b-td>{{ item.etat }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">Année prévisionnelle</b-th>
                        <b-td>{{ item.annee_prev }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">Surface</b-th>
                        <b-td>{{ verify_null_number(item.surface, 'm²') }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="head">Identifiant</b-th>
                        <b-td>{{ item.identifiant }}</b-td>
                    </b-tr>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'ZoneTva',
    data()
    {
        return {};
    },
    methods: {
        verify_null_number: tools.verify_null_number
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.table {
    .head {
        background-color: #9abeca;
        width: 15rem;
    }
}
</style>
