<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col class="pl-0 pr-0"
            ><h4>Marché locatif à {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="8">
                <div>
                    <p class="sub_title">
                        Nous reprenons ici les loyers mensuels moyens par typologie observés
                        sur les 12 derniers mois dans la commune (charges comprises).
                    </p>
                </div>
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-tr>
                        <b-th>{{ item.nom_iris }}</b-th>
                        <b-th v-for="(item, index) in header_top" :key="index">{{
                                item
                            }}
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-th>Loyer mensuel moyen</b-th>
                        <b-td
                          class="align-middle"
                          v-for="(item, index) in loyer_moyen_mensuel"
                          :key="index"
                        >
                            {{ verify_null_currency(item) }}
                        </b-td
                        >
                    </b-tr>
                    <b-tr>
                        <b-th>SHAB moyenne</b-th>
                        <b-td
                          class="align-middle"
                          v-for="(item, index) in shab_moyen"
                          :key="index"
                        >{{ item }}
                        </b-td
                        >
                    </b-tr>
                    <b-tr>
                        <b-th>Loyer mensuel moyen au m²</b-th>
                        <b-td
                          class="align-middle"
                          v-for="(item, index) in loyer_moyen_mensuel_mc"
                          :key="index"
                        >{{ item }} €
                        </b-td
                        >
                    </b-tr>
                </b-table-simple>
            </b-col>
            <b-col col md="4" v-if="composition_parc">
                <p class="sub_title">
                    Composition du parc locatif privé par nombre de pièces
                </p>
                <apexchart
                  type="donut"
                  height="200"
                  :series="composition_parc"
                  :options="Options"
                ></apexchart>
                <p class="sub_title">
                    {{ item.selected_marche_locatif[0].taux_locatif_prive_rp }} % de
                    résidences principales en locatif privé sur la zone
                </p>
            </b-col>
        </b-row
        >
        <b-row>
            <b-col>
                <div class="div_paragraphe">
                    <p class="paragraphe">
                        Pour un loyen moyen CC de
                        {{ item.selected_marche_locatif[0].loyer_moyen_commune }} €/m²
                    </p>
                </div>

                <br/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_currency} from './functions_utilities';

export default {
    name: 'MarcheLocatif',
    data()
    {
        return {
            composition_parc: [],
            loyer_moyen_mensuel: [],
            loyer_moyen_mensuel_mc: [],
            shab_moyen: [],
            header_top: [
                'Studio',
                '2 pièces',
                '3 pièces',
                '4 pièces',
                '5 pièces et +'
            ],
            Options: {
                height: '300px',
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true
                        }
                    }
                },
                labels: [
                    '% studios dans parc locatif privé',
                    '% t2 dans parc locatif privé',
                    '% t3 dans parc locatif privé',
                    '% t4 dans parc locatif privé',
                    '% t5 et plus dans parc locatif privé'
                ],
                legend: {
                    show: false
                },
                colors: [
                    '#FFA400',
                    '#345995',
                    '#E40066',
                    '#03CEA4',
                    '#FB4D3D',
                    '#775DD0',
                    '#00D9E9',
                    '#FF66C3'
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 200,
                                width: 200
                            }
                        }
                    }
                ]
            }
        };
    },
    methods: {
        verify_null_currency
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    },
    watch: {
        item: function (newValue, olValue)
        {
            let selected = newValue.selected_marche_locatif[0];
            this.composition_parc.push(
              selected.part_studio_prive,
              selected.part_t2_prive,
              selected.part_t3_prive,
              selected.part_t4_prive,
              selected.part_t5_sup_prive
            );
            this.loyer_moyen_mensuel.push(
              selected.loyer_moyen_mensuel_studio,
              selected.loyer_moyen_mensuel_t2,
              selected.loyer_moyen_mensuel_t3,
              selected.loyer_moyen_mensuel_t4,
              selected.loyer_moyen_mensuel_t5
            );
            this.shab_moyen.push(
              selected.shab_moyen_studio,
              selected.shab_moyen_t2,
              selected.shab_moyen_t3,
              selected.shab_moyen_t4,
              selected.shab_moyen_t5
            );
            this.loyer_moyen_mensuel_mc.push(
              selected.loyer_moyen_mensuel_mc_studio,
              selected.loyer_moyen_mensuel_mc_t2,
              selected.loyer_moyen_mensuel_mc_t3,
              selected.loyer_moyen_mensuel_mc_t4,
              selected.loyer_moyen_mensuel_mc_t5
            );
        }
    }
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.sub_title {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}

.div_paragraphe {
    display: flex;
    justify-content: flex-start;

    .paragraphe {
        display: inline-block;
        width: auto;
        text-align: left;
        border-bottom: 1px red solid;
    }
}
</style>
