<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>Programme : {{ item.nom }}</h4>
                <p class="text-left">
                    Promoteur : <span class="font-weight-bold">{{ item.compagnie }}</span>
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="12">
                <b-table-simple class="table" responsive bordered fixed small>
                    <b-thead>
                        <b-th>Typologies</b-th>
                        <b-th>SHAB moyenne</b-th>
                        <b-th>Prix moyen</b-th>
                        <b-th>Prix moyen au m²</b-th>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>Studio</b-td>
                            <b-td>{{ verify_null_number(item.shab_moy_t1, ' m²') }}</b-td>
                            <b-td>{{ verify_null_currency(item.prix_moy_t1) }}</b-td>
                            <b-td>{{
                                    verify_null_currency(item.prix_moy_t1_m2, '/m²')
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>T2</b-td>
                            <b-td>{{ verify_null_number(item.shab_moy_t2, ' m²') }}</b-td>
                            <b-td>{{ verify_null_currency(item.prix_moy_t2) }}</b-td>
                            <b-td>{{
                                    verify_null_currency(item.prix_moy_t2_m2, '/m²')
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>T3</b-td>
                            <b-td>{{ verify_null_number(item.shab_moy_t3, ' m²') }}</b-td>
                            <b-td>{{ verify_null_currency(item.prix_moy_t3) }}</b-td>
                            <b-td>{{
                                    verify_null_currency(item.prix_moy_t3_m2, '/m²')
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>T4</b-td>
                            <b-td>{{ verify_null_number(item.shab_moy_t4, ' m²') }}</b-td>
                            <b-td>{{ verify_null_currency(item.prix_moy_t4) }}</b-td>
                            <b-td>{{
                                    verify_null_currency(item.prix_moy_t4_m2, '/m²')
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>T5</b-td>
                            <b-td>{{ verify_null_number(item.shab_moy_t5, ' m²') }}</b-td>
                            <b-td>{{ verify_null_currency(item.prix_moy_t5) }}</b-td>
                            <b-td>{{
                                    verify_null_currency(item.prix_moy_t5_m2, '/m²')
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>
                    Prix moyen projet: {{ verify_null_currency(item.prix_m2, '/m²') }}
                </p>
            </b-col>
        </b-row>
        <b-row
        >
            <b-col
            ><p>
                * Prix affichés par le promoteur au taux de TVA en vigueur sur
                l'opération.
            </p></b-col
            >
        </b-row
        >
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'ProgrammeNeuf',
    data()
    {
        return {};
    },
    methods: {
        verify_null_currency(number, typo = '')
        {
            if (number)
            {
                number = new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(number);
                return number + typo;
            }
            else
            {
                return 'Donnée inconnue';
            }
        },
        verify_null_number(number, typo = '')
        {
            if (number)
            {
                number = new Intl.NumberFormat('fr-FR', {
                    style: 'decimal'
                }).format(number);
                return number + typo;
            }
            else
            {
                return 'Donnée inconnue';
            }
        }
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0;
    }
}

#graph {
    .titre_graph {
        width: 100%;
        border-bottom: 2px #58a4b0 solid;

        p {
            margin: 0;
            float: left;
            font-weight: bold;
        }

        span {
            float: right;
            font-weight: bold;
        }
    }
}
</style>
