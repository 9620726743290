<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col class="pl-0 pr-0"
            ><h4>Données générales - marché collectif à {{ item.nom_com }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <p class="paragraphe mb-1">
                Pour une SHAB moyenne vendue de
                {{
                    verify_null_number(
                      item.selected_marche_ancien[0].shab_moy_mediane,
                      'm²'
                    )
                }}
            </p>
            <b-col col md="auto">
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <td></td>
                            <b-th>
                                Médian - 2023
                            </b-th>
                            <b-th>
                                Médian - 25% les + chers - 2023
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>
                                <b>Prix unitaire</b>
                            </b-td>
                            <b-td>{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0].prix_median_2018
                                    )
                                }}
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0].prix_median_2018_25_plus_cher
                                    )
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>
                                <b>Prix au m²</b>
                            </b-td>
                            <b-td>{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0].prix_median_pmc,
                                      '/m²'
                                    )
                                }}
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0].prix_median_dernier_quartile,
                                      '/m²'
                                    )
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>
                                <b>SHAB moyenne</b>
                            </b-td>
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].shab_moy_mediane,
                                      'm²'
                                    )
                                }}
                            </b-td>
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].shab_moy_dernier_quartile,
                                      'm²'
                                    )
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <b-col col md="auto">
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <td></td>
                            <b-th>
                                Evolution dernière année
                            </b-th>
                            <b-th>
                                Evolution sur 5 ans
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>
                                <b>Prix unitaire</b>
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_percent(
                                      item.selected_marche_ancien[0]
                                        .evolution_prix_derniere_annee,
                                      1,
                                      true
                                    )
                                }}
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_percent(
                                      item.selected_marche_ancien[0].evolution_prix_neuf_ans,
                                      1,
                                      true
                                    )
                                }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>
                                <b>Prix au m²</b>
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_percent(
                                      item.selected_marche_ancien[0].evolution_pmc_derniere_annee,
                                      1,
                                      true
                                    )
                                }}
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_percent(
                                      item.selected_marche_ancien[0].evolution_pmc_neuf_ans,
                                      1,
                                      true
                                    )
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col col md="6">
                <p class="paragraphe">
                    Nombre de transactions moyen sur les 4 dernières années (année en cours exclue)
                </p>
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>
                                Min
                            </b-th>
                            <b-th>
                                Moy
                            </b-th>
                            <b-th>
                                Max
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].min_transaction_an
                                    )
                                }}
                            </b-td>
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].moy_transaction_an
                                    )
                                }}
                            </b-td>
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].max_transaction_an
                                    )
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <b-col col md="6">
                <p class="paragraphe">
                    Répartition des ventes par nombre de pièces
                </p>
                <apexchart
                  type="donut"
                  height="300"
                  :series="repartition"
                  :options="Options"
                ></apexchart>
            </b-col>
        </b-row>
        <b-row id="titre">
            <b-col class="pl-0 pr-0"
            ><h4>Données générales - marché individuel à {{ item.nom_com }}</h4>
            </b-col>
        </b-row>

        <b-row>
            <b-col col md="auto">
                <p class="paragraphe mb-1">
                  Nombre de transactions moyen sur les 4 dernières années (année en cours exclue)
                </p>
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>
                                Min
                            </b-th>
                            <b-th>
                                Moy
                            </b-th>
                            <b-th>
                                Max
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>
                                {{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].maison_min_transaction_an
                                    )
                                }}
                            </b-td>
                            <b-td>
                                {{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].maison_moy_transaction_an
                                    )
                                }}
                            </b-td
                            >
                            <b-td>{{
                                    verify_null_number(
                                      item.selected_marche_ancien[0].maison_max_transaction_an
                                    )
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <b-col col md="auto">
                <p class="paragraphe mb-1">
                    Pour une SHAB moyenne vendue de
                    {{
                        verify_null_number(
                          item.selected_marche_ancien[0].maison_shab_moy_mediane,
                          'm²'
                        )
                    }}
                </p>
                <b-table-simple bordered>
                    <b-thead>
                        <b-tr>
                            <td></td>
                            <b-th>
                                Médian - année 2023
                            </b-th>
                            <b-th>
                                Médian - 25% les + chers - année 2023
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td>
                                <b>Prix unitaire</b>
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0].maison_prix_median_2018
                                    )
                                }}
                            </b-td>
                            <b-td
                            >{{
                                    verify_null_currency(
                                      item.selected_marche_ancien[0]
                                        .maison_prix_median_2018_25_plus_cher
                                    )
                                }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {
    verify_null_currency,
    verify_null_number,
    verify_null_percent
} from './functions_utilities';

export default {
    name: 'MarcheAncien',
    data()
    {
        return {
            repartition: [],
            Options: {
                height: '300px',
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true
                        }
                    }
                },
                labels: [
                    '% studios',
                    '% 2 pièces',
                    '% 3 pièces',
                    '% 4 pièces',
                    '% 5 pièces et plus'
                ],
                legend: {
                    show: true,
                    position: 'bottom'
                },
                colors: [
                    '#FFA400',
                    '#345995',
                    '#E40066',
                    '#03CEA4',
                    '#FB4D3D',
                    '#775DD0',
                    '#00D9E9',
                    '#FF66C3'
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 200,
                                width: 200
                            }
                        }
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    },
    watch: {
        item: function (newValue, olValue)
        {
            let selected = newValue.selected_marche_ancien[0];
            this.repartition.push(
              parseFloat(selected.part_transaction_studio),
              parseFloat(selected.part_transaction_t2),
              parseFloat(selected.part_transaction_t3),
              parseFloat(selected.part_transaction_t4),
              parseFloat(selected.part_transaction_t5_sup)
            );
        }
    },
    methods: {
        verify_null_currency,
        verify_null_number,
        verify_null_percent
    }
};
</script>

<style lang="scss" scoped>
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.paragraphe {
    width: 100%;
    text-align: left;
    border-bottom: 1px red solid;
}
</style>
