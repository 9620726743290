<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="12">
                <h4>Gentrification à {{ item.nom_iris }}</h4>
            </b-col>
            <b-col>
                <b-alert show dismissible>
                    <p>
                        La gentrification correspond &agrave; l'&eacute;volution des classes
                        de la population en professions interm&eacute;diaires et cadres
                        entre 2010 et 2020.
                    </p>
                </b-alert>
            </b-col>
        </b-row>
        <b-row id="graph">
            <div class="titre_graph">
                <p>
                    Évolution ({{ annee_tab[0] }}-{{ annee_tab[annee_tab.length - 1] }}):
                </p>
                <span
                  :style="{
            'color':
              evolution_iris(
                pop_iris_array[pop_iris_array.length - 1],
                pop_iris_array[0]
              ) >= 0
                ? 'green'
                : 'red'
          }"
                >{{
                        evolution_iris(
                          pop_iris_array[pop_iris_array.length - 1],
                          pop_iris_array[0]
                        )
                    }}%</span
                >
            </div>
            <b-col col md="12">
                <apexchart
                  type="line"
                  height="350"
                  :series="evoLineSeries"
                  :options="Options"
                ></apexchart>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Gentrification',
    data()
    {
        return {
            pop_iris_array: [],
            annee_tab: []
        };
    },
    methods: {
        evolution_iris: function (item, item2)
        {
            return parseFloat((((item - item2) / item2) * 100.0).toFixed(1));
        }
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        evoLineSeries: function ()
        {
            return [
                {
                    name:
                      'Professions intermediaires, cadres et intellectuels supérieurs',
                    data: this.pop_iris_array
                }
            ];
        },
        Options: function ()
        {
            return {
                dataLabels: {
                    enabled: true
                },
                markers: {
                    size: 6
                },
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: this.annee_tab
                },
                yaxis: [
                    {
                        forceNiceScale: true,
                        min: Math.min(...this.pop_iris_array),
                        max: Math.max(...this.pop_iris_array),
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB'
                            }
                        },
                        title: {
                            text: 'Population',
                            style: {
                                color: '#008FFB'
                            }
                        }
                    }
                ]
            };
        }
    },
    watch: {
        item: function (newValue, olValue)
        {
            let liste_iris = [];
            let annee = [];
            for (const element of newValue.selected_pop_iris)
            {
                liste_iris.push(element.pop);
                annee.push(element.annee);
            }
            this.pop_iris_array = [...liste_iris];
            this.annee_tab = [...annee];
        }
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

#graph {
    .titre_graph {
        width: 100%;
        border-bottom: 2px #58a4b0 solid;

        p {
            margin: 0;
            float: left;
            font-weight: bold;
        }

        span {
            float: right;
            font-weight: bold;
        }
    }
}
</style>
