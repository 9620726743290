<template>
    <div>
        <v-list-item class="pt-2">
            <h5 style="text-align:left; margin: 0;">
                Type de commerces
            </h5>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
            <b-form-checkbox-group
              style="text-align: left;"
              id="btnradios3"
              stacked
              button-variant="outline-secondary"
              v-model="getFilter"
              :options="keyOptions"
              name="radioBtnStacked"
            />
        </v-list-item>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'FiltreOperationAmen',
    data()
    {
        return {
            keyOptions: [
                {text: 'Activité', value: 'Activité'},
                {
                    text: 'En phase de réflexion',
                    value: 'En phase de réflexion'
                },
                {
                    text: 'Espace ouvert / Parc',
                    value: 'Espace ouvert / Parc'
                },
                {text: 'Logement', value: 'Logement'},
                {
                    text: 'Mixte Logement / Activité',
                    value: 'Mixte Logement / Activité'
                },
                {
                    text: 'Mixte Logement / Activité / Equipements',
                    value: 'Mixte Logement / Activité / Equipements'
                },
                {text: 'Mixte Autre', value: 'Mixte Autre'},
            ],
            filter_selected: [
                'Activité',
                'En phase de réflexion',
                'Espace ouvert / Parc',
                'Logement',
                'Mixte Logement / Activité',
                'Mixte Logement / Activité / Equipements',
                'Mixte Autre',
            ]
        };
    },
    mounted()
    {
        if (this.saved_filter.length === 0)
        {
            this.$store.commit('mutateFilter', this.filter_selected);
        }
    },
    watch: {
        filter_selected(newValue, oldValue)
        {
            this.$store.commit('mutateFilter', newValue);
        }
    },
    computed: {
        ...mapGetters({saved_filter: 'getFilter'}),
        getFilter: {
            get()
            {
                return this.saved_filter;
            },
            set(newSaved_filter)
            {
                this.filter_selected = newSaved_filter;
            }
        }
    }
};
</script>

<style lang="scss"></style>
