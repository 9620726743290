<template>
    <div>
        <h5 class="flex justify-self-center pt-2 h4">
            Légende
        </h5>
        <v-divider class="mt-0"></v-divider>
        <v-list-item dense>
            <img :src="require('@/assets/maternelle.png')" alt="icon_maternelle"/>
            <span class="body-2">
        École maternelle
      </span>
        </v-list-item>
        <v-list-item dense>
            <img :src="require('@/assets/primaire.png')" alt="icon_maternelle"/>
            <span class="body-2">
        École primaire / Elémentaire
      </span>
        </v-list-item>
        <v-list-item dense>
            <img :src="require('@/assets/school.png')" alt="icon_maternelle"/>
            <span class="body-2">
        Collège / Lycée
      </span>
        </v-list-item>
        <v-list-item dense>
            <img :src="require('@/assets/secondaire.png')" alt="icon_maternelle"/>
            <span class="body-2">
        Supérieur
      </span>
        </v-list-item>
        <v-list-item dense>
            <img :src="require('@/assets/question.png')" alt="icon_maternelle"/>
            <span class="body-2">
        Privé / Autres
      </span>
        </v-list-item>
    </div>
</template>

<script>
export default {
    data()
    {
        return {};
    }
};
</script>

<style>
img {
    width: 25px;
    margin-right: 10px;
}
</style>
