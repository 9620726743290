var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('b-container',[_c('b-row',{attrs:{"id":"titre"}},[_c('b-col',{attrs:{"col":"","md":"12"}},[_c('h4',[_vm._v("Gentrification à "+_vm._s(_vm.item.nom_iris))])]),_c('b-col',[_c('b-alert',{attrs:{"show":"","dismissible":""}},[_c('p',[_vm._v(" La gentrification correspond à l'évolution des classes de la population en professions intermédiaires et cadres entre 2010 et 2020. ")])])],1)],1),_c('b-row',{attrs:{"id":"graph"}},[_c('div',{staticClass:"titre_graph"},[_c('p',[_vm._v(" Évolution ("+_vm._s(_vm.annee_tab[0])+"-"+_vm._s(_vm.annee_tab[_vm.annee_tab.length - 1])+"): ")]),_c('span',{style:({
        'color':
          _vm.evolution_iris(
            _vm.pop_iris_array[_vm.pop_iris_array.length - 1],
            _vm.pop_iris_array[0]
          ) >= 0
            ? 'green'
            : 'red'
      })},[_vm._v(_vm._s(_vm.evolution_iris( _vm.pop_iris_array[_vm.pop_iris_array.length - 1], _vm.pop_iris_array[0] ))+"%")])]),_c('b-col',{attrs:{"col":"","md":"12"}},[_c('apexchart',{attrs:{"type":"line","height":"350","series":_vm.evoLineSeries,"options":_vm.Options}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }