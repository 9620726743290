<template>
    <div>
        <v-list-item class="m-0">
            <p class="mb-0 mt-2" style="text-align:left;font-family:Gotham;">
                Type de points d'intérêt
            </p>
        </v-list-item>
        <v-list-item class="m-0">
            <v-divider></v-divider>
        </v-list-item>
        <v-list-item>
            <b-form-checkbox-group
              style="text-align: left;"
              id="btnradios3"
              stacked
              button-variant="outline-secondary"
              v-model="getFilter"
              :options="keyOptions"
              name="radioBtnStacked"
            />
        </v-list-item>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'FiltrePointInteret',
    data()
    {
        return {
            keyOptions: [
                {text: 'Préfecture', value: 'Préfecture'},
                {text: 'Sous-Préfecture', value: 'Sous-Préfecture'},
                {text: 'Pompiers', value: 'Pompiers'},
                {text: 'Hôpital', value: 'Hôpital'},
                {text: 'Gendarmerie/Police', value: 'Gendarmerie/Police'},
                {text: 'Mairie', value: 'Mairie'}
            ],
            filter_selected: [
                'Préfecture',
                'Sous-Préfecture',
                'Pompiers',
                'Hôpital',
                'Gendarmerie/Police',
                'Mairie'
            ]
        };
    },
    mounted()
    {
        if (this.saved_filter.length === 0)
        {
            this.$store.commit('mutateFilter', this.filter_selected);
        }
    },
    watch: {
        filter_selected(newValue, oldValue)
        {
            this.$store.commit('mutateFilter', newValue);
        }
    },
    computed: {
        ...mapGetters({saved_filter: 'getFilter'}),
        getFilter: {
            get()
            {
                return this.saved_filter;
            },
            set(newSaved_filter)
            {
                this.filter_selected = newSaved_filter;
            }
        }
    }
};
</script>

<style></style>
