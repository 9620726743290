var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('b-container',[_c('b-row',{attrs:{"id":"titre"}},[_c('b-col',{attrs:{"col":"","md":"12"}},[_c('h4',[_vm._v("Permis de construire : "+_vm._s(_vm.item.depositair))])])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-left"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Numéro de permis :")]),_vm._v(" "+_vm._s(_vm.item.num_pc ? _vm.item.num_pc : 'Donnée inconnue')+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Déposé en :")]),_vm._v(" "+_vm._s(_vm.item.date_depot ? _vm.item.date_depot : 'Donnée inconnue')+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Adresse :")]),_vm._v(" "+_vm._s(_vm.item.adresse ? _vm.item.adresse : 'Donnée inconnue')+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Nombre de logements :")]),_vm._v(" "+_vm._s(_vm.verify_null_number(_vm.item.nblog))+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Type de résidence :")]),_vm._v(" "+_vm._s(_vm.item.typere ? _vm.item.typere : 'Donnée inconnue')+" ")])])]),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('div',{attrs:{"id":"pie_chart_1"}},[_c('apexchart',{attrs:{"width":"300","type":"pie","options":_vm.chartOptions,"series":[
          parseFloat(Number(_vm.item.part_collectif).toFixed(1)),
          parseFloat(Number(_vm.item.part_individuel).toFixed(1))
        ]}})],1)])],1),_c('p',{staticClass:"paragraphe"},[_vm._v(" Répartition des typologies ")]),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('div',{attrs:{"id":"pie_chart_2"}},[_c('apexchart',{attrs:{"width":"250","type":"pie","options":_vm.chartOptions_2,"series":[
          parseInt(_vm.item.nbt1),
          parseInt(_vm.item.nbt2),
          parseInt(_vm.item.nbt3),
          parseInt(_vm.item.nbt4),
          parseInt(_vm.item.nbt5),
          parseInt(_vm.item.nbt6)
        ]}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center"},[_c('b-table-simple',{staticClass:"table",attrs:{"responsive":"","bordered":"","fixed":"","small":""}},[_c('b-thead',[_c('b-th',[_vm._v("Studio")]),_c('b-th',[_vm._v("T2")]),_c('b-th',[_vm._v("T3")]),_c('b-th',[_vm._v("T4")]),_c('b-th',[_vm._v("T5")]),_c('b-th',[_vm._v("T6")])],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt1)))]),_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt2)))]),_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt3)))]),_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt4)))]),_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt5)))]),_c('b-td',[_vm._v(_vm._s(_vm.verify_null_number(_vm.item.nbt6)))])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"text-muted text-left"},[_vm._v(" Notons que les permis de construire ci-dessus sont géolocalisés grâce aux informations disponibles sur la base Sitadel.2 (adresses, parcelles, etc.), cependant certaines informations peuvent venir à manquer. Ainsi, malgré tout le soin apporté par INOVEFA au travail de retraitement, certains permis de construire sont absents de la présente carte. Les données ne sauraient être exhaustives. ")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }